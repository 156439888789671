import React from "react";
import logo from "../../../../assets/AD_PI_CC_V5/img/logo1.png";

const Footer = () => {
  return (
    <footer className="mt-5 agent_footer">
      <div className="container">
        <div className="row">
          <div className=" col-lg-12">
            {/* <img src={logo} /> */}
            <img src="" />
            <p>
              Accell Digital Limited. Company Number: 13539012. VAT Number:
              389529826. Registered Address: Accell Digital, 7 Bell Yard, London
              WC2A 2JR. ICO Number: ZB239690.
            </p>
            <p>
              Accell Digital is Authorised and Regulated by The Financial
              Conduct Authority in respect of regulated claims management
              activities. Firm Reference Number FRN964366.
            </p>
            <ul>
              <li>
                <a href="/privacy-policy" target="_blank">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/cookie-policy" target="_blank">
                  Cookie Policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
