import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { getQuestion, isUnacceptable } from "../../../../Constants/QuestionsV5";
import AcceptableModal from "../../../UI/AcceptableModal";
import GtmDataLayer from "../../GtmDataLayer";
import RadioQuestion from "./RadioQuestion";

const WorkingWithSolicitor = ({ name, index, next, prev }) => {
  const { trigger, watch } = useFormContext();
  const question = getQuestion(name);

  const [showModal, setShowModal] = useState(false);

  const proceed = (e) => {
    trigger(name).then((isValid) => {
      if (!isValid) return;

      if (isUnacceptable(name, watch(name))) {
        setShowModal(true);
        return;
      }

      next();

      GtmDataLayer({
        question: question?.tag,
        answer: watch(name),
      });
    });
  };

  return (
    <>
      <RadioQuestion
        question={question}
        name={name}
        index={index}
        next={proceed}
        prev={prev}
      />

      {showModal && (
        <AcceptableModal
          showModal={showModal}
          setShowModal={setShowModal}
          text="Sorry, If you have received solicitor assistance, we can't help you"
        />
      )}
    </>
  );
};

export default WorkingWithSolicitor;
