import React from "react";
import { Modal } from "react-bootstrap";

const AcceptableModal = ({ showModal, setShowModal, text = null }) => {
  return (
    <Modal
      id="sorry_modal"
      lass="modal fade bd-example-modal-lg"
      show={showModal}
      onHide={() => {}}
      centered={true}
    >
      <Modal.Body className="bord-b text-center">
        <h5>
          {text ??
            "Sorry, If you are not injured in the last 3 years, we can't help you."}
        </h5>
        <button
          type="button"
          className="custom-mdal-btn btn btn-primary"
          id="unqlfyBtn"
          data-bs-dismiss="modal"
          onClick={() => {
            setShowModal(false);
          }}
        >
          Ok
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default AcceptableModal;
