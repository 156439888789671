import React, { useContext, useState } from "react";
import { useEmailValidation } from "../../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../../Hooks/usePhoneValidation";
import { useEmailWebhook } from "../../../../../Hooks/useEmailWebhook";
import VisitorParamsContext from "../../../../../Contexts/VisitorParams";
import { currentdate, currenttime } from "../../../../../Constants/Constants";
import Telephone from "../../../../UI/Telephone";
import Email from "../../../../UI/Email";

const ChatBotContactDetails = ({
  validation,
  validationMsg,
  trigger,
  prevSlide,
  getValues,
  setError,
  formSubmit,
  setSubmitClicked,
}) => {
  const { emailValidation, emailValidationErr } = useEmailValidation();
  const { weHookApiCall } = useEmailWebhook();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [disabled, setDisabled] = useState(false);
  const [loader, setloader] = useState(false);
  const [privacyShow, setPrivacyShow] = useState(false);
  const [webHookState, setWebHookState] = useState(false);

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      console.log(getPhoneValidation.data);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (emailValidationErr) {
        return 1;
      }
      if (
        typeof getEmailValidation === "undefined" ||
        getEmailValidation === null
      ) {
        return 2;
      }

      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email Address",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const validNumber = (evt) => {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  const submitForm = async (e) => {
    setDisabled(true);
    setloader("loader_img");
    const formvalues = getValues();
    const emailValidChk = await trigger("txtEmail");
    const phoneValidChk = await trigger("txtPhone");

    if (emailValidChk && phoneValidChk) {
      if ((await emailValidCheck()) === 2 || emailValidationErr) {
        if (!webHookState) {
          const message = `
      {
        Service Name : Email Single Endpoint API
        Project ID : ADPI060
        UUID : ${visitorParameters.visitor_parameters.uuid}
        Error Reason : Validation API Timeout
        Datetime : ${currentdate} ${currenttime}
      }
      `;
          const getWebhookRes = await weHookApiCall(message);
          console.log("api delay");
        }
        setWebHookState(true);

        let mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!formvalues["txtEmail"].match(mailformat)) {
          setError("txtEmail", {
            type: "manual",
            message: "Please enter valid Email Address",
            shouldFocus: true,
          });
          return false;
        }

        setDisabled(true);
      }

      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        console.log("message:");
        setDisabled(true);
        formSubmit();
      } else {
        setDisabled(false);
        setloader(false);
      }
    } else {
      setDisabled(false);
      setloader(false);
    }
  };

  return (
    <div className="text-msg mesg10 sel-option btn-spc">
      <div className=" btn-spc mt-1 text-center">
        <div className="mb-3 p_num text-start">
          <label htmlFor="phone_cot">Phone Number</label>
          <Telephone
            name="txtPhone"
            className="form-control"
            placeholder="Phone Number"
            maxlength="11"
            onBlur={phoneValidCheck}
            onKeyPress={validNumber}
            validation={validation({
              pattern: {
                value: /^[0-9]*$/i,
                message: "Please Enter Valid Phone Number",
              },
              required: "Please Enter Valid Phone Number",
            })}
            // validationMsg={
            //   validationMsg.txtPhone && validationMsg.txtPhone.message
            // }
          ></Telephone>
          {validationMsg.txtPhone && (
            <span className="error_msg " id="phone_err">
              {validationMsg.txtPhone.message}
            </span>
          )}
        </div>
        <div className="mb-4 e_add text-start">
          <label htmlFor="Email_cot">Email</label>
          <Email
            name="txtEmail"
            className="form-control"
            placeholder="Email Address"
            onBlur={emailValidCheck}
            validation={validation({
              required: "Please Enter Valid Email Address",
              // pattern: {
              //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              //   message: "Invalid Email Address",
              // },
            })}
            // validationMsg={
            //   validationMsg.txtEmail && validationMsg.txtEmail.message
            // }
          ></Email>
          {validationMsg.txtEmail && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtEmail.message}
            </span>
          )}
        </div>
        <div className="mb-1 pt-2">
          <span
            className="action-btn  text-message-item medibutton w-100 d-block"
            data-type="4th"
            onClick={() => {
              submitForm();
              setTimeout(() => {
                setSubmitClicked(true);
              }, [1000]);
            }}
          >
            Submit
          </span>
        </div>
        {/* <a
          name="working_with_attorney"
          className="back_btn sctop1"
          onClick={prevSlide}
        >
          <img src="assets/AD_PI_V3/img/arrow.png" alt="" /> Previous{" "}
        </a> */}
      </div>
    </div>
  );
};

export default ChatBotContactDetails;
