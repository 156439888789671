import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const PrivacyPopUp = ({ privacyShow, setPrivacyShow }) => {
  return (
    <>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        show={privacyShow}
        onHide={() => setPrivacyShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Privacy Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>Purpose</b></p>

          <p></p>

          <p>
            We have created this Privacy Policy to set out your rights about the personal data we hold about you and
            how Accell Digital and any 3rd&#8239;parties we engage with collect, handle and protect your personal
            data, and the choices you have about it. Accell Digital is committed to ensuring that your privacy is
            protected. Should we ask for certain information by which you can be identified, then you can be assured
            that it will only be used in accordance with this document.
          </p>

          <p><b>Definitions and Interpretation</b></p>

          <p>In this Policy the following terms shall have the following meanings:</p>

          <p>
            &ldquo;Data&rdquo; means collectively all information that you submit to Accell Digital and it&rsquo;s
            associated trading names. This definition shall, where applicable, incorporate the definitions provided
            in the Data Protection Act 1998;
          </p>

          <p>
            &ldquo;Accell Digital&rdquo; means Accell Digital Ltd of registered address 7 BELL YARD, LONDON WC2A 2JR
          </p>

          <p>
            &ldquo;UK and EU Cookie Law&rdquo; means the Privacy and Electronic Communications (EC Directive)
            Regulations 2003 as amended by the Privacy and Electronic Communications (EC Directive) (Amendment)
            Regulations 2011;
          </p>

          <p>
            &ldquo;User&rdquo; means any third party that accesses the Website and is not employed by Accell Digital
            Ltd and acting in the course of their employment; and
          </p>

          <p>&ldquo;Website&rdquo; means the website that you are currently using (
            <a href="https://www.accelldigital.co.uk/">www.accelldigital.co.uk</a>
            ) unless expressly excluded by their own terms and conditions.</p>

          <p><b>Who are we</b></p>

          <p>Accell Digital,</p>

          <p>7 Bell Yard,</p>

          <p>London,</p>

          <p>WC2A 2JR.</p>

          <p>Registered in England and Wales.</p>

          <p>ICO Number: ZB239690.</p>

          <p>VAT Number &ndash; 389529826.</p>

          <p>FCA Firm Reference Number FRN964366</p>

          <p><b>Data Protection Officer</b></p>

          <p>If you have any questions about this policy, please&#8239;contact us.</p>

          <p><b>Data collection</b></p>

          <p>The information collected is (but not limited to):</p>
          <ul>
            <li>name</li>

            <li>date of birth</li>

            <li>job title</li>

            <li>profession</li>

            <li>contact information such as email addresses, telephone numbers and physical address</li>

            <li>demographic information such as postcode, preferences and interests</li>

            <li>IP address (automatically collected)</li>

            <li>And any information that relates specifically to the service provided on the website</li>
          </ul>

          <p><b>Data Transfers</b></p>

          <p>
            Accell Digital may transfer personal data to countries outside of the UK and/or EEA. If data is
            transferred outside of the EEA, Accell Digital will put in place Standard Contractual Clauses with the Data
            Controller or Data Processor which contractually obliges them to protect your information to the same
            standard required by the General Data Protection Regulation and Data Protection Act 2018 post 31 December
            2020.
          </p>

          <p><b>Log data</b></p>

          <p>
            When you use the Accell Digital website, we record your Internet Protocol (IP) which is automatically sent
            by your browser.
          </p>

          <p><b>How your personal data is used</b></p>

          <p>
            In order to provide our services to you it is necessary to collect the information we do. This allows us
            to provide an interesting, relevant and personal experience. The information may be used to:-
          </p>
          <ul>
            <li>Maintain internal record keeping</li>

            <li>Improve the products and services offered to you</li>

            <li>Keep you up to date on Accell Digital activities, products and services</li>

            <li>Contact you for the provision of the service/s</li>

            <li>Answer questions and comments</li>
          </ul>
          <p>In addition to the above, with your consent we will:-</p>
          <ul>
            <li>Send you promotional information about third parties which we think you may find interesting</li>
          </ul>
          <p><b>How your personal information is shared</b></p>

          <p>
            Some of the information you provide is shared with 3rd&#8239;parties to comply with law or enable us to
            provide a service to you.
          </p>
          <p>We share information with:-</p>
          <ul>

            <li>
              Payment companies used to make payment to Accell Digital. The information shared is what is necessary
              for you to make the payment.
            </li>

            <li>If requested by law enforcement or government agencies to comply with the law.</li>

            <li>Third party websites and services</li>
          </ul>
          <p>
            Accell Digital may, from time to time, employ the services of other parties for dealing with matters that
            may include, but are not limited to, payment processing, delivery of purchased items, search engine
            facilities, advertising and marketing.&#8239; The providers of such services have access to certain
            personal Data provided by Users of this Website.  Any data used by such parties is used only to the
            extent required by them to perform the services that Accell Digital requests.&#8239; Any use for
            other purposes is strictly prohibited.&#8239; Furthermore, any Data that is processed by third parties
            shall be processed within the terms of this Policy.
          </p>

          <p><b>Security and storage</b></p>

          <p>
            We are committed to ensuring your data is secure. In order to prevent unauthorised access or disclosure,
            we have suitable physical, electronic and managerial procedures to safeguard and secure the information we
            collect.
          </p>

          <p>
            Information and records relating to service users will be stored securely and will only be accessible to
            authorised personnel. We will hold your details for as long as you use our services, or as long regulations
            stipulate, whichever is the longer.
          </p>

          <p>
            We may store the information we hold about you outside of your home country. The privacy policies and laws
            to access your information by law enforcement in these countries may be different to your home country. If
            data is transferred outside the European Economic Area (EEA) it is only to countries that have been deemed
            to provide an adequate level of protection for personal data by the European Commission.
          </p>

          <p><b>Your rights</b></p>

          <p>You have options which you can apply in relation to the information we have about you, to do this please
            contact us. The options are explained below:-
          </p>
          <ol>
            <li>
              If you wish to access the information we hold about you, we&rsquo;ll usually provide it within 30 days
              of receiving your request.
            </li>

            <li>
              You can ask us to stop using your information, please&#8239;contact us&#8239;to do this. If you wish to
              stop any emails/ texts we send, you may&#8239;unsubscribe&#8239;at any time by emailing us:{' '}
              <a href="mailto:hello@accelldigital.co.uk">hello@accelldigital.co.uk</a>.
            </li>

            <li>
              You can ask us to send the information we hold about you to another organisation, where it is technically
              possible to do.
            </li>

            <li>
              If you think we haven&rsquo;t complied with the data protection laws, please let us at Accell Digital
              &#8239;know in the first instance, to give us time to investigate or you can log a complaint to
              the&#8239;ICO
            </li>

            <li>If you wish to update or delete the information we hold about you please&#8239;contact us.</li>
          </ol>
          <p><b>Consent</b></p>
          <ul>
            <li>
              Upon your consent, Accell Digital may contact you via the following methods, by email and telephone
              promoting our products and services to you.
            </li>

            <li>If you do not wish to receive emails or calls promoting our products and services, please&#8239;
              unsubscribe&#8239;If you wish to know more about how your data is handled, please&#8239;contact us.
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setPrivacyShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrivacyPopUp;
