import React, { useState } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';


const ProgressBars = ({ value }) => {
  if(value >0){
  return(
    <>
    <ProgressBar now={value} label={`${value}%`} visuallyHidden/>
    {/* <p className="text-center prgrs_txt" id="progressBar_content">Progress {value}%</p> */}
    </>
  )
  }else{
    return(
      <>
      <ProgressBar now={value} label={`${value}%`} visuallyHidden/>
      </>
    )
  }
};
export default ProgressBars;
