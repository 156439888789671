import React, { useEffect } from "react";
import "../../assets/css/complaints_procedure.scss";

const ComplaintsProcedure = () => {
  useEffect(() => {
    // Change the font family when this component is mounted
    document.body.style.fontFamily = "Arial, sans-serif";

    // Revert back to the default font family when the component is unmounted
    return () => {
      document.body.style.fontFamily = "Roboto, sans-serif"; // Replace with your default font
    };
  }, []);
  return (
    <>
      <div id="complaints-procedure">
        <header className="header-section">
          <h1>Complaints Procedure</h1>
          <p>
            We believe that if you wish to make a complaint or register a
            concern you should be able to do that quickly and easily.
          </p>
        </header>
        <div className="container">
          <div className="content-section">
            <h2>Purpose</h2>
            <p>
              The purpose of this policy is to ensure that if you wish to
              complain about the service you receive you should understand how
              to do so, how quickly your complaint will be investigated and the
              timescales for receiving a resolution.
            </p>
            <h2>Informal resolution process</h2>
            <p>
              We are confident that an open and positive discussion will resolve
              the majority of problems swiftly. If you are dissatisfied with the
              service you have received, please raise your concerns with the
              person handling your case first, either by phone or in writing. If
              necessary, a manager will investigate your complaint and attempt
              to resolve your complaint.
            </p>
            <p>
              If this is not possible, please write to the Accell Digital
              Limited at:
            </p>
            <p>
              7 Bell Yard, London WC2A 2JR
              <br />
              <a
                className="complaints-a-tag"
                href="mailto:Hello@accelldigital.co.uk"
              >
                Hello@accelldigital.co.uk
              </a>
              <br />
              020 7126 8245
            </p>
            <h2>Formal resolution process</h2>
            <h3>Stage one</h3>
            <p>
              Within two working days of receiving your complaint, we will send
              you a letter acknowledging receipt and providing a copy of this
              procedure. We will arrange for a thorough investigation of your
              complaint.
            </p>
            <p>
              Within four weeks of the date of the acknowledgement letter, you
              will receive a detailed written response to your complaint,
              including any suggestions for resolving the issue.
            </p>
            <h3>Stage two</h3>
            <p>
              If your complaint remains unresolved, you may report it to Emma
              Hindz, our Managing Director.
            </p>
            <p>
              Emma will review your case and write to you within four weeks of
              receiving your review request, confirming our final decision on
              your complaint and explaining our reasoning.
            </p>
            <p>
              If your complaint has not been resolved, you may contact the Legal
              Ombudsman. They will investigate your complaint independently, and
              their findings will have no effect on how we handle your case.
              Before accepting a complaint for investigation, the Legal
              Ombudsman will ensure that you have first attempted to resolve it
              with us. Any complaint to the Legal Ombudsman must normally be
              submitted within six months of our final decision on your
              complaint and no later than six years from the date of the
              act/omission – or three years from when you should have reasonably
              understood there was a basis for complaint. The Legal Ombudsman’s
              contact information is as follows:
            </p>
            <p>
              PO Box 6806, Wolverhampton, WV1 9WJ
              <br />
              Telephone: 0300 555 0333
              <br />
              Overseas callers: +44 121 245 3050
              <br />
              Minicom users: 0300 555 1777
              <br />
              Email:{" "}
              <a
                className="complaints-a-tag"
                href="mailto:enquiries@legalombudsman.org.uk"
              >
                enquiries@legalombudsman.org.uk
              </a>
            </p>
          </div>
        </div>
        <footer className="footer">
          <p>© Cliams Gurus 2022 All Rights Reserved</p>
        </footer>
      </div>
    </>
  );
};

export default ComplaintsProcedure;
