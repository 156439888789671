import React, { useEffect, useState } from "react";
import review from "../../../../assets/AD_PI_CC_V5/img/review1.gif";
import "../../../../assets/AD_PI_CC_V5/css/style.scss";

const Loader = ({ timeout }) => {
  const [analysing, setAnalysing] = useState(true);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAnalysing(false);
    }, 2000);

    setTimeout(() => {
      setCompleted(true);
    }, timeout);
  }, [timeout]);

  useEffect(() => {
    if (analysing) return;

    setTimeout(() => {}, 4000);
  }, [analysing]);
  return (
    <div
      className={`modal  fade formmodal ${!completed && "show"}`}
      style={{ backgroundColor: "rgba(0, 0, 0,.9)" }}
      id="analyzemodal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        {analysing && (
          <div id="slide6n" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <img src={review} />
            </div>
          </div>
        )}
        {!analysing && (
          <div
            id="slide6nxt"
            className="bg-white col-12 slide-pad text-center p-5 fade-in"
            style={{ animationDelay: "0.3s" }}
          >
            <div className="form-group text-center" id="analize1">
              <h2>Great News!</h2>
              <h4
                className="fade-in"
                style={{ animationDelay: "2s" }}
                id="slide6nxt-txt2"
              >
                It appears you may have a successful compensation claim.
              </h4>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Loader;
