import React from "react";
import "../../assets/Thankyou/css/Thankyou.scss";
import Footer from "../Includes/Layouts/AD_PI_V3/Footer";

const Thankyou = () => {
  localStorage.clear();
  return (
    <>
      <div className="personal_injury" id="personal_injury">
        <div className="wrapper">
          <section className="thankyou_page">
            <div className="container">
              <div className="row">
                <div className="offset-lg-1 col-lg-10 offset-md-1 col-md-10 col-sm-12 col-12 text-center">
                  <img src="/assets/Thankyou/img/thankyou-white.png" alt="" />
                  <h1>We are on it</h1>
                  <h4>
                    Thank you for contacting us. We will get back to you as soon as possible to discuss your enquiry. We
                    are open 7 days a week, and, to meet the needs of our customers, we are now offering callbacks
                    between 8am - 7pm.
                  </h4>
                  <h4>
                    We know that these are uncertain times, so rest assured, we will aim to contact you at a time that
                    is suitable.
                  </h4>
                  <h4>We'll call. We'll listen. We'll help.</h4>
                  {/* <a href="/UKPI_V1">
                <input type="button" className="btn btn-primary" defaultValue="Visit Website" /></a> */}
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Thankyou;
