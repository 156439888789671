import React from "react";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import "../../assets/AD_PI_CC_V5/css/agent.scss";
import "../../assets/AD_PI_CC_V5/css/animate.css";
import agentIcon from "../../assets/AD_PI_CC_V5/img/agent_icon.png";
import Footer from "../Includes/Layouts/AD_PI_CC_V5/Footer";
import Header from "../Includes/Layouts/AD_PI_CC_V5/Header";
import CallButton from "../Includes/Layouts/Common/CallButton";

const Agent = () => {
  return (
    <>
      <GetVisitorsParams />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Header />

        <section className="py-0 py-md-2 SECT ">
          <div className="container p-0">
            <div className="slide_bg1 col-lg-10 offset-lg-1 col-md-12">
              <div className="form_sec mb-5">
                <img src={agentIcon} className="agent_img" />

                <h1 className="agent_head">Agent Available</h1>
                <h4 className="mt-3 slid_txth">
                  Act Fast! A representative is ready and waiting for your call.
                </h4>
                <p className="mt-3 slid_txt1">
                  Simply tap the blue button below to connect with one of our
                  agents.
                </p>

                {/* <a
                  className="call icon_option_num icon_option_num_a text-white"
                  href="#"
                >
                  {" "}
                  XXX-XXX-XXXXX
                </a> */}

                <CallButton />
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
};
export default Agent;
