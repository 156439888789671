import React, { useState } from "react";
import PrivacyPopUp from "./PrivacyPopUp";
import CookiePopUp from "./CookiePopUp";

const Footer = () => {
  const [privacyShow, setPrivacyShow] = useState(false);
  const [cookieShow, setCookieShow] = useState(false);

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className=" col-lg-12">
              <img src="assets/AD_PI_V4/img/logo1.jpg" alt="" />
              <p style={{ color: "#000" }}>
                Claims Gurus is a trading style of Accell Digital Limited.
              </p>
              <p style={{ color: "#000" }}>
                Accell Digital Limited. Company Number: 13539012. VAT Number:
                389529826. Registered Address: Accell Digital, 7 Bell Yard,
                London WC2A 2JR. ICO Number: ZB239690.
              </p>
              <p style={{ color: "#000" }}>
                Accell Digital is Authorised and Regulated by The Financial
                Conduct Authority in respect of regulated claims management
                activities. Firm Reference Number FRN964366.
              </p>
              <ul>
                <li>
                  <a onClick={() => setPrivacyShow(true)}>Privacy Policy</a>
                </li>
                <li>
                  <a onClick={() => setCookieShow(true)}>Cookie Policy</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

      <PrivacyPopUp privacyShow={privacyShow} setPrivacyShow={setPrivacyShow} />
      <CookiePopUp cookieShow={cookieShow} setCookieShow={setCookieShow} />
    </>
  );
};
export default Footer;
