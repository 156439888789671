import { useCallback, useState } from "react";

const useMultiPartForm = (parts, onChange = () => {}) => {
  const [step, setStep] = useState(0);
  const [stepStack, setStepStack] = useState([]);

  const maxIndex = parts.length - 1;

  const next = useCallback(() => {
    const oldStep = step;
    const newStep = Math.min(maxIndex, step + 1);
    setStep(newStep);

    if (!parts[step].ignorePrev) {
      setStepStack([...stepStack, step]);
    }

    onChange({
      currentStep: newStep,
      isForward: true,
      lastStep: oldStep,
    });
  }, [maxIndex, step, stepStack]);

  const prev = useCallback(() => {
    if (!stepStack.length) return;

    const oldStep = step;
    const prevStep = stepStack.pop();
    setStep(prevStep);

    setStepStack([...stepStack]);

    onChange({
      currentStep: prevStep,
      isForward: false,
      lastStep: oldStep,
    });
  }, [step, stepStack]);

  const activatePart = useCallback(
    (label) => {
      setStep(parts.findIndex((p) => p.label === label));
      setStepStack([...stepStack, step]);
    },
    [parts, step, stepStack]
  );

  const isFirstStep = step === 0;

  const isLastStep = step === maxIndex;

  return {
    currentIndex: step,
    maxIndex,
    prev,
    next,
    activatePart,
    isFirstStep,
    isLastStep,
  };
};

export default useMultiPartForm;
