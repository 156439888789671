const GtmDataLayer = ({ question, answer, conversionvalue, ...otherProps }) => {
  if (answer) {
    window.dataLayer = window.dataLayer || [];

    const data = {
      event: "slideclick",
      question,
      answer,
      conversionvalue,
      ...otherProps,
    };

    try {
      const conversionValue = localStorage.getItem("conversionValue");
      const typeOfInjury = localStorage.getItem("typeOfInjury");

      if (conversionValue) {
        data.conversionvalue = conversionValue;
      }

      if (typeOfInjury) {
        data.injurytype = typeOfInjury;
      }
    } catch (error) {
      console.error(error);
    }

    console.log("GTM DataLayer", data);

    window.dataLayer.push(data);
  }

  return <></>;
};

export default GtmDataLayer;
