import { useState } from "react";
import { Api } from "../api/Api";

export const useUserClicks = () => {
  const [isLoading, setIsLoading] = useState(false);

  const saveUserClicks = async (uuid, click_type, status) => {
    setIsLoading(true);

    try {
      const response = await Api.post("api/v1/save-user-clicks", {
        uuid,
        click_type,
        status,
      });

      setIsLoading(false);

      return response;
    } catch (error) {
      console.log({ error });

      setIsLoading(false);
    }
  };

  return { saveUserClicks, isLoading };
};
