export const split_Flow = {
  // default:{
  //     1:'Split_1',
  //     2:'Signature',
  //     3:'Proceed',
  //     4:'Thankyou'
  // },
  AD_PI_V3: {
    1: "AD_PI_V3",
    2: "thankyou",
  },

  AD_PI_V4: {
    1: "AD_PI_V4",
    2: "thankyou",
  },

  AD_PI_V4_B: {
    1: "AD_PI_V4_B",
    2: "thankyou",
  },

  AD_PI_V6: {
    1: "AD_PI_V6",
    2: "thankyou",
  },

  "AD_PI_V6.1": {
    1: "AD_PI_V6.1",
    2: "thankyou",
  },

  AD_PI_V7: {
    1: "AD_PI_V7",
    2: "thankyou",
  },

  AD_PI_CC_V5: {
    1: "AD_PI_CC_V5",
    2: "agent",
  },
};
