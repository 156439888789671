import { WebHookApi} from "../api/Api";

export const useEmailWebhook = () => {
  const weHookApiCall = async (
    message
  ) => {
    const response = await WebHookApi.post("", {
        text: message,
    });
    return response;
  };
  return { weHookApiCall };
};