import React, { useMemo } from "react";
import SelectBox from "../../../../UI/SelectBox";
import { Salutation } from "../../../../../Constants/Questions";
import TextField from "../../../../UI/TextField";
import GtmDataLayer from "../../../GtmDataLayer";

const ChatBotBasicDetails = ({
  validation,
  validationMsg,
  trigger,
  setError,
  clearErrors,
  getValues,
  setFullName,
}) => {
  //   Salutation?.shift();
  //   Salutation.unshift({
  //     label: "",
  //     value: "",
  //   });

  const nextSlide = async (e) => {
    let errorFlag = 0;
    let txtLNameResult;
    let txtFNameResult;
    const values = getValues();
    const formDataT = values;
    const txtFirstName = formDataT.txtFName.trim();
    const txtLastName = formDataT.txtLName.trim();

    var lstSalutationResult = await trigger("lstSalutation");
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
      document.getElementById("txtLName").focus();
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }

    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      return false;
    }

    if (!txtFNameResult || !txtLNameResult) {
      errorFlag = 1;
    }

    if (txtFirstName.length < 3) {
      setError("txtFName", {
        type: "manual",
        message: "Your First Name is Invalid. Please Recheck",
      });
      document.getElementById("txtFName").focus();
      errorFlag = 1;
      return false;
    }

    if (txtLastName.length < 3) {
      setError("txtLName", {
        type: "manual",
        message: "Your Last Name is Invalid. Please Recheck",
      });
      document.getElementById("txtLName").focus();
      errorFlag = 1;
      return false;
    }

    if (errorFlag === 0) {
      //   slideChange(e);
      GtmDataLayer({
        question: "Legal Name",
        answer: "Completed",
      });
    } else {
      return false;
    }

    setFullName(fullName);
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  const fullName = useMemo(() => {
    if (
      !!getValues("lstSalutation")?.length &&
      !!getValues("txtFName")?.length &&
      !!getValues("txtLName")?.length
    ) {
      const name = `${getValues("lstSalutation")} ${getValues(
        "txtFName"
      )} ${getValues("txtLName")}`;
      return name;
    }
  }, [
    getValues("lstSalutation"),
    getValues("txtFName"),
    getValues("txtLName"),
  ]);
  return (
    <>
      <div className=" mesg02b sel-option mt-1">
        <div className="text-msg btn-spc">
          <div className=" btn-spc mt-1 text-center">
            <div className="mb-3 f_name text-start">
              <label htmlFor="format2">Title</label>
              <SelectBox
                className="form-select form-control"
                OptionValue={Salutation}
                name="lstSalutation"
                id="lstSalutation"
                onChange={() => clearErrors("lstSalutation")}
                clearErrors={clearErrors}
                myRef={validation({ required: "Please Select Title" })}
                validationMsg={
                  validationMsg.lstSalutation &&
                  validationMsg.lstSalutation.message
                }
              ></SelectBox>
            </div>
            <div className="mb-3 f_name text-start">
              <label>First Name</label>
              <TextField
                type="text"
                className="form-control"
                // placeholder="First Name"
                name="txtFName"
                id="txtFName"
                onBlur={namevalidate}
                validation={validation({
                  required: "Please Enter First Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid First Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.txtFName && validationMsg.txtFName.message
                }
              ></TextField>
            </div>
            <div className="mb-4 l_name text-start">
              <label>Last Name</label>
              <TextField
                type="text"
                className="form-control"
                // placeholder="Surname"
                name="txtLName"
                id="txtLName"
                onBlur={namevalidate}
                validation={validation({
                  required: "Please Enter Surname",
                  minLength: {
                    value: 2,
                    message: "Please Enter Valid Surname",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Surname is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.txtLName && validationMsg.txtLName.message
                }
              ></TextField>
            </div>
            <div className="mb-1 pt-2">
              <span
                className="action-btn  text-message-item mesg3 w-100 d-block"
                id="noInsurance2"
                onClick={(e) => nextSlide(e)}
              >
                Next
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBotBasicDetails;
