import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
      <FbViewPixel />
      <header className="d-block d-md-block p-2 bg-white"> 
        <div className="container text-center"> 
        <img src="assets/AD_PI_V4/img/logo1.png" className="logo_1" alt="" />
        </div>
    </header>
    </>
  );
};

export default Header;