import { useCallback, useContext, useMemo } from "react";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { useQueryString } from "./useQueryString";

export const useUuid = () => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { urlSearchParams } = useQueryString();

  const getUuid = useCallback(() => {
    if (!!visitorParameters.visitor_parameters.uuid) {
      return visitorParameters.visitor_parameters.uuid;
    }

    return urlSearchParams.get("uuid");
  }, [urlSearchParams, visitorParameters.visitor_parameters.uuid]);
  const uuid = useMemo(() => getUuid(), [getUuid]);

  return { getUuid, uuid };
};
