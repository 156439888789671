import React from "react";
import { Accordion } from "react-bootstrap";

const Faq = () => {
  return (
    <section className="frequentlysection">
      <div className="container">
        <div>
          <div className="mb-5 text-center">
            <h2 className="fw-bold title">Frequently Asked Questions</h2>
            <p className="px-5">
              Find the answers to the most commonly asked questions regarding
              Personal Injury, Medical Negligence, and Housing Disrepair claims.
            </p>
          </div>
          <div>
            <Accordion flush id="accordionExample">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  Am I eligible to make a claim?
                </Accordion.Header>
                <Accordion.Body>
                  When an incident occurs which causes you to be injured or
                  become ill, due to someone else’s fault, you may be able to
                  claim compensation. Compensation Gurus handle thousands of
                  personal injury claims, for all types injuries and illnesses.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  How much compensation will I receive?
                </Accordion.Header>
                <Accordion.Body>
                  Each accident has a unique physical and financial impact, the
                  average payment is mainly influenced by the severity of your
                  injuries. However, we can give you an idea of how much
                  compensation you can claim when you speak to us.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>What is No Win, No Fee?</Accordion.Header>
                <Accordion.Body>
                  Injury cases are conducted on a No Win, No Fee* basis. With no
                  financial risk to you, we can help you receive the
                  compensation you deserve.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>How do I start my claim?</Accordion.Header>
                <Accordion.Body>
                  Starting your claim with us is simple. When you contact us
                  we’ll ask you some details about your accident, injury and
                  recovery. This is so that we can fully understand the cause,
                  what happened and the impact your injury has had on your life.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
