import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import ConsentSection from "./ConsentSection";

const HowDidyouGetInjured = ({ className, slideChange, textHeading, validation, prevSlide,setGtmvalue }) => {
  const [active5, setActive5] = useState("");
  const [active6, setActive6] = useState("");
  const [active7, setActive7] = useState("");
  const [active8, setActive8] = useState("");
  const [active23, setActive23] = useState("");
  const [active24, setActive24] = useState("");
  const [active25, setActive25] = useState("");
  const [active26, setActive26] = useState("");
  const [active27, setActive27] = useState("");
  const nextSlide = async (e) => {
    setGtmvalue(e.target.getAttribute("convert-value"));
    if (e.target.value == 5) {  
      setActive5("active");
    } else {
      setActive5("");
    }

    if (e.target.value == 6) {
      setActive6("active");
    } else {
      setActive6("");
    }

    if (e.target.value == 7) {
      setActive7("active");
    } else {
      setActive7("");
    }

    if (e.target.value == 8) {
      setActive8("active");
    } else {
      setActive8("");
    }

    if (e.target.value == 23) {
      setActive23("active");
    } else {
      setActive23("");
    }

    if (e.target.value == 24) {
      setActive24("active");
    } else {
      setActive24("");
    }

    if (e.target.value == 25) {
      setActive25("active");
    } else {
      setActive25("");
    }

    if (e.target.value == 26) {
      setActive26("active");
    } else {
      setActive26("");
    }

    if (e.target.value == 27) {
      setActive27("active");
    } else {
      setActive27("");
    }
    slideChange(e);
  };
  return (
    <>
      <div id="slide7" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center  p-3">
          <div className="mb-3">
            <label className="fw-bold mb-1 qstn_1">{textHeading}</label>

            <RadioButton
              value="5"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Accident At Work"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active5}`}
              onClick={nextSlide}
              validation={validation()}
              conversion="100"
            />

            <RadioButton
              value="23"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Accident In a Private Place (Shop, Bar, Restaurant etc.)"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active23}`}
              onClick={nextSlide}
              validation={validation()}
              conversion="50"
            />

            <RadioButton
              value="24"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Accident In a Public Place (Park, Pavement etc.)"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active24}`}
              onClick={nextSlide}
              validation={validation()}
              conversion="10"
            />

            {/* <RadioButton
              value="6"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Medical Procedure"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active6}`}
              onClick={nextSlide}
              validation={validation()}
            /> */}

            {/* <RadioButton
              value="7"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Slip Trip or Fall"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active7}`}
              onClick={nextSlide}
              validation={validation()}
            /> 
            <RadioButton
              value="8"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Road Traffic Accident"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active8}`}
              onClick={nextSlide}
              validation={validation()}
              conversion="10"
            />

             <RadioButton
              value="25"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Criminal Injury"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active25}`}
              onClick={nextSlide}
              validation={validation()}
              conversion="25"
            />

            <RadioButton
              value="26"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Industrial Disease"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active26}`}
              onClick={nextSlide}
              validation={validation()}
              conversion="33"
            />*/}

            <RadioButton
              value="27"
              name="how_did_you_injured"
              className="radio-button custom-radio-button  next-but"
              labelName="Other"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active27}`}
              onClick={nextSlide}
              validation={validation()}
               conversion="1"
            />
          </div>
          <a name="were_you_injured" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/AD_PI_V4/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text p-3">
          <img src="assets/AD_PI_V4/img/ico-safe.png" className="safe-text" alt="" /> Safe | Secure | Privacy Protected
        </div>
      </div>
    </>
  );
};
export default HowDidyouGetInjured;
