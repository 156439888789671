
import {Api} from "../api/Api";

export const useFollowupUserQuestions = () => {
    const saveFollowupUserQuestions = async (question_data,followup_data,message_type) => {
        const questionnaireSubmit = await Api.post("/api/v1/data-ingestion-pipeline", {
            question_data,
            followup_data,
            message_type,
        });
        return questionnaireSubmit;
    }
    return { 
       
        saveFollowupUserQuestions
    }
}