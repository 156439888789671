import React, { useState, useContext } from "react";
import RadioButton from "../../../UI/RadioButton";
import Popup from "./Popup";
import { useVisitorAnswer } from "../../../../Hooks/useVisitorAnswer";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import ConsentSection from "./ConsentSection";

const WereYouInjured = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
}) => {
  const [active1, setActive1] = useState("");
  const [active2, setActive2] = useState("");
  const [shwPopup, setshwPopup] = useState(false);
  const { saveAnswer } = useVisitorAnswer();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const nextSlide = async (e) => {
    if (e.target.value == 1) {
      setActive1("active");
    } else {
      setActive1("");
    }

    if (e.target.value == 2) {
      // const saveAns = await saveAnswer(2, e.target.value, visitorParameters.visitor_parameters);
      setActive2("active");
      setshwPopup(true);
      return false;
    } else {
      setActive2("");
    }
    slideChange(e);
  };

  return (
    <>
      <Popup
        show={shwPopup}
        setshwPopup={setshwPopup}
        message="Sorry, If you are not injured in the last 3 years, we can't help you."
      />
      <div id="slide1" className={className}>
        <div className=" col-lg-12 col-md-12 form_cont text-center  p-3">
          <div className="my-0 my-md-3 mb-3">
            <label className="fw-bold mb-1 qstn_1">{textHeading}</label>
            <RadioButton
              value="1"
              name="were_you_injured"
              className="radio-button custom-radio-button next-but"
              labelName="Yes"
              labelClassName={`icon_option  text-white fw-bold ${active1}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="2"
              name="were_you_injured"
              className="radio-button custom-radio-button"
              labelName="No"
              labelClassName={`icon_option  text-white fw-bold  mt-3 ${active2}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <a
            name="how_did_you_injured"
            className="back_btn sctop1"
            onClick={prevSlide}
          >
            <img src="assets/AD_PI_V3/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text p-3">
          <img
            src="assets/AD_PI_V4/img/ico-safe.png"
            className="safe-text"
            alt=""
          />
          <span>Safe | Secure | Privacy Protected</span>
        </div>
      </div>
    </>
  );
};
export default WereYouInjured;
