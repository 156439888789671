import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import ConsentSection from "./ConsentSection";

const TypeOfInjury = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
}) => {
  const [active9, setActive9] = useState("");
  const [active10, setActive10] = useState("");
  const [active11, setActive11] = useState("");
  const [active12, setActive12] = useState("");
  const [active13, setActive13] = useState("");
  const [active14, setActive14] = useState("");
  const [active15, setActive15] = useState("");
  const [active16, setActive16] = useState("");
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [modalClass1, SetModalClass1] = useState("hide");
  const [modalClass2, SetModalClass2] = useState("hide");

  const nextSlide = async (e) => {
    if (e.target.value == 9) {
      setActive9("active");
    } else {
      setActive9("");
    }

    if (e.target.value == 10) {
      setActive10("active");
    } else {
      setActive10("");
    }

    if (e.target.value == 11) {
      setActive11("active");
    } else {
      setActive11("");
    }

    if (e.target.value == 12) {
      setActive12("active");
    } else {
      setActive12("");
    }

    if (e.target.value == 13) {
      setActive13("active");
    } else {
      setActive13("");
    }

    if (e.target.value == 14) {
      setActive14("active");
    } else {
      setActive14("");
    }

    if (e.target.value == 15) {
      setActive15("active");
    } else {
      setActive15("");
    }

    if (e.target.value == 16) {
      setActive16("active");
    } else {
      setActive16("");
    }
    slideChange(e);
  };
  return (
    <>
      <div id="slide7" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center  px-3 py-0 py-sm-3">
          <div className="mb-3">
            <label className="fw-bold mb-1 qstn_1">{textHeading}</label>

            <RadioButton
              value="9"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Head"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active9}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="10"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Brain"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active10}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="11"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Upper Body"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active11}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="12"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Lower Body"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active12}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="13"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Internal Organs"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active13}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="14"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Multiple Body Parts"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active14}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="15"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Psychological"
              labelClassName={`icon_option text-white fw-bold nopad mb-3 ${active15}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="16"
              name="type_of_injury"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Others"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active16}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <a
            name="working_with_attorney"
            className="back_btn sctop1"
            onClick={prevSlide}
          >
            <img src="assets/AD_PI_V3/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text p-3">
          <img
            src="assets/AD_PI_V3/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
      </div>
    </>
  );
};
export default TypeOfInjury;
