import React from 'react';

const RadioButtonFlpQuest = ({
    id,
    value,
    name,
    className,
    labelName,
    labelClassName,
    onClick,
    onLabelClick,
    validation,
    labelCountString
  }) => {
    return (
        <>
          <input type="radio" className={className} name={name} id={id} data-ans-id={value} data-quest-id={name}
            value={value} onClick={onClick} 
        />
          <label htmlFor={id} data-ans-id={value} data-quest-id={name} className={labelClassName} 
                onClick={onLabelClick}>
                <span  className="line-break-span" >{labelCountString}</span>{labelName}
          </label>
        </>
      );
}

export default RadioButtonFlpQuest;