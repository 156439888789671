import React from 'react';
import AdtopiaLoadRoot from '../../Utility/AdtopiaLoadRoot';

const HomePage = () => {
  const renderSplitName = () => {
  }
  return (
    <>
      <AdtopiaLoadRoot
        pageType="home"
        renderSplitName={renderSplitName}
        splitName={process.env.REACT_APP_LP_DEFAULT_URL}
      />
    </>
  )
}

export default HomePage;