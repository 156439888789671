import React, { useContext, useEffect } from 'react';
import { useAdtopiaLoadApi } from '../Hooks/useAdtopiaLoadApi';
import { AppDomainWithoutProtocol, AppDomain, AppLpDefaultUrl, AppAdtopiaUPID, AppReferrerURL } from "../Constants/EnvConstants";
import { UUID } from "./UUID";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../Contexts/VisitorParams";
import { useUserAgent } from "../Hooks/useUserAgent";
import { useDataIngestion } from "../Hooks/useDataIngestion";

const AdtopiaLoadLP = ({ splitName }) => {
  const history = useHistory();
  const referrer = document.referrer;
  const { getUUID, setParams } = UUID();
  const { adtopiaLoadResponse, adtopiaLoadingErr } = useAdtopiaLoadApi();
  const { getUserAgent } = useUserAgent();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const urlParams = new URLSearchParams(window.location.search);
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  const encryptVal = urlParams.get('value');
  const field = 'uuid';
  var uuid = "";
  var ip_address = "";
  var queryString = "";
  if (query_string_arr != null) {
    queryString = query_string_arr[1];
  }
  // useEffect(async () => {
  useEffect(() => {
      (async () => {    
    let uuidType = 'new';
    if (encryptVal) {
      uuidType = 'new';
    } else {
      uuidType = 'check';
    }
    if (urlParams.get('uuid')) {
      uuid = urlParams.get('uuid');
      ip_address = localStorage.getItem('ip_address');
    } else {
      const getUUIDParam = await getUUID(
        splitName,
        uuidType
      );
      uuid = getUUIDParam.data.response.uuid;
      ip_address = getUUIDParam.data.response.ip_address;
      localStorage.setItem('ip_address', ip_address);
    }
    if (encryptVal) {
      const getdtopiaApi = await adtopiaLoadResponse(
        AppDomain + '/' + splitName + '?' + queryString,
        AppDomainWithoutProtocol,
        window.navigator.userAgent,
        ip_address,
        uuid,
        AppAdtopiaUPID,
        AppReferrerURL
      );
      if (adtopiaLoadingErr) {
        history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
      }
      if (typeof getdtopiaApi !== "undefined") {
        const getdtopiaApiResponse = getdtopiaApi.data;
        if (getdtopiaApiResponse.response === 200) {
          if (getdtopiaApiResponse.domain_name === AppDomainWithoutProtocol) {
            if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
              history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param);
            } else {
              history.push('/' + getdtopiaApiResponse.split_full_name + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid);
            }
          } else {
            window.location.href = getdtopiaApiResponse.url + '&uuid=' + uuid;
          }
        } else if (getdtopiaApiResponse.response === 404) {
          if (getdtopiaApiResponse.url_param != "") {
            if (getdtopiaApiResponse.url_param.indexOf('&' + field + '=') != -1) {
              history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param);
            } else {
              history.push('/' + AppLpDefaultUrl + '?' + getdtopiaApiResponse.url_param + '&uuid=' + uuid);
            }
          } else {
            history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
          }
        }
      } else {
        history.push('/' + AppLpDefaultUrl + '?uuid=' + uuid);
      }
    } else {
      const response = await getUserAgent(
        window.navigator.userAgent,
        "LP",
        queryString,
        AppDomain,
        window.location.href,
        referrer
      );
      visitorParameters.data = response.data.response.data;
      visitorParameters.visitor_parameters = { uuid: uuid };
      (async () => {
        setParams(uuid, splitName, ip_address)
        await saveDataIngestion(
          visitorParameters.visitor_parameters,
          visitorParameters.data,
          'split_page_load',
          splitName,
          queryString
        )
      })();
      if (queryString != null) {
        if (queryString.indexOf('&' + field + '=') != -1 ||
          queryString.indexOf('?' + field + '=') != -1 ||
          queryString.indexOf(field + '=') != -1) {
          window.history.pushState({}, '', '?' + queryString);
        } else {
          window.history.pushState({}, '', '?' + queryString + '&uuid=' + uuid);
        }
      } else {
        window.history.pushState({}, '', '?uuid=' + uuid);
      }
    }
  // }, [encryptVal])
})()
}, [encryptVal])
  return (
    <></>
  )
}

export default AdtopiaLoadLP;