const questinnare1   = {
  questionId:5,
  question : "Q1 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Yes",
              value: "14"
          },
          {
              label: "No",
              value: "15"
          }
  ]
};
const questinnare2   = {
  questionId:6,
  question : "Q2 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Yes",
              value: "16"
          },
          {
              label: "No",
              value: "17"
          }
  ]
};
const questinnare3   = {
  questionId:7,
  question : "Q3 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Yes",
              value: "18"
          },
          {
              label: "No",
              value: "19"
          }
  ]
};
const questinnare4   = {
  questionId:8,
  question : "Q4 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Yes",
              value: "20"
          },
          {
              label: "No",
              value: "21"
          }
  ]
};
const questinnare5   = {
  questionId:9,
  question : "Q5 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Yes",
              value: "22"
          },
          {
              label: "No",
              value: "23"
          }
  ]
};
const questinnare6   = {
  questionId:10,
  question : "Q6 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Yes",
              value: "24"
          },
          {
              label: "No",
              value: "25"
          }
  ]
};

const questinnare7Sub1 = {
  question:"Q7 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  questionId:'sub1',
  answers: [
          {
              label: "Option-1",
              value: "Option-1"
          },
          {
              label: "Option-2",
              value: "Option-2"
          },
          {
              label: "Option-3",
              value: "Option-3"
          },
          {
              label: "Option-4",
              value: "Option-4"
          },
          {
              label: "Option-5",
              value: "Option-5"
          }
  ]
} 
const questinnare7Sub2 = {
    question:"Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    questionId:'sub2',
    answers: [
            {
                label: "Yes",
                value: "yes"
            },
            {
                label: "No",
                value: "no"
            }
    ]
  }
const questinnare7   = {
  questionId:11,
  question : "Q7 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Option-1",
              value: "26"
          },
          {
              label: "Option-2",
              value: "27"
          },
          {
              label: "Option-3",
              value: "28"
          },
          {
              label: "Option-4",
              value: "29"
          },
          {
              label: "Option-5",
              value: "30"
          },
          {
              label: "Option-6",
              value: "31"
          },
          {
              label: "Option-7",
              value: "32"
          },
          {
              label: "Option-8",
              value: "33"
          },
          {
              label: "Option-9",
              value: "34"
          },
          {
              label: "Option-10",
              value: "35"
          },
          {
              label: "Option-11",
              value: "36"
          },
          {
              label: "Option-12",
              value: "37"
          },
          {
              label: "Option-13",
              value: "38"
          },
          {
              label: "Option-14",
              value: "39"
          },
  ]
};
const questinnare8   = {
  questionId:12,
  question : "Q8 - Lorem Ipsum is simply dummy text of the printing and typesetting industry",
  answers: [
          {
              label: "Yes",
              value: "40"
          },
          {
              label: "No",
              value: "41"
          }
  ]
};

const Salutation    =   [
    {
        label:"Select Title",
        value:""
    },
    {
        label:"Mr.",
        value:"Mr"
    },
    {
        label:"Mrs.",
        value:"Mrs"
    },
    {
        label:"Ms.",
        value:"Ms"
    },
    {
        label:"Miss.",
        value:"Miss"
    },
    {
        label:"Dr.",
        value:"Dr"
    },
    {
        label:"Rev.",
        value:"Rev"
    },
    {
        label:"Dame.",
        value:"Dame"
    },
    {
        label:"Lady.",
        value:"Lady"
    },
    {
        label:"Sir.",
        value:"Sir"
    },
    {
        label:"Lord.",
        value:"Lord"
    },
  ];
  const questinnare9   = {
    questionId:14,
    question : "Were you sold any other products in the past where you don't think this bank treated you fairly, which you would like to be reviewed?",
    answers: [
            {
                label: "Yes",
                value: "44"
            },
            {
                label: "No",
                value: "45"
            }
    ]
  };

  const questinnare10   = {
    questionId:15,
    question : "Please name the products you would like to be reviewed?"
  };
  

  const questinnare11   = {
    questionId:16,
    question : "Did you expect the advice you were given to be the best for your circumstances?",
    answers: [
            {
                label: "Yes",
                value: "46"
            },
            {
                label: "No",
                value: "47"
            }
    ]
  };
  const questinnare12   = {
    questionId:17,
    question : "Does the bank contacting you over the phone cause you anxiety and stress?",
    answers: [
            {
                label: "Yes",
                value: "48"
            },
            {
                label: "No",
                value: "49"
            }
    ]
  };
export { 
  questinnare1,
  questinnare2,
  questinnare3,
  questinnare4,
  questinnare5,
  questinnare6,
  questinnare7,
  questinnare8,
  Salutation,
  questinnare7Sub1,
  questinnare7Sub2,
  questinnare11,
  questinnare12,
  questinnare9,
  questinnare10
};
