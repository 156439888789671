import React from "react";
import "../../../../assets/AD_PI_V4_B/css/layout_style.scss";
import TPlogo from "../../../../assets/AD_PI_V4_B/img/Trustpilot-logo.png";
import NoFeeGuaranteeImg from "../../../../assets/AD_PI_V4_B/img/NoFeeGuarantee.png";
import startsImg from "../../../../assets/AD_PI_V4_B/img/starts.jpg";
import Faq from "./Faq";
import arrowGif from "../../../../assets/AD_PI_V4_B/img/fc6c95b9-o-arrow.gif";

const AD_PI_V4_B_Layout = ({ children }) => {
  return (
    <>
      <section className="py-0 py-md-2 SECT ">
        <div className="container p-0">
          <div className="form_part bg-white px-0 px-md-2 py-0 py-md-3">
            <h1 className="d-block text-center fw-bold mb-0 head-class">
              Take This 30 Second Assessment To Help Maximise Your Potential
              Compensation
            </h1>
            {/* <h4 className="d-none d-sm-block d-md-block d-lg-block text-center">
              We offer a FREE no obligation, no win-no fee consultation. Get
              started below by answering a few questions. It's quick and easy.
            </h4>
            <div className="sm-device-txt">
              <h1 className="d-block d-sm-none d-md-none d-lg-none text-center text-white fw-bold">
                Take This 30 Second Assessment To Help Maximise Your Potential
                Compensation
              </h1>
              <h2 className="d-block d-sm-none d-md-none d-lg-none text-center">
                We offer a FREE no obligation, no win-no fee consultation. Get
                started below by answering a few questions. It's quick and easy.
              </h2>
            </div> */}

            {/* add if needed for V4_B */}
            <div className="AD_PI_V4_B_Layout">
              <div className="row bannersubtext mt-2">
                <div className="col-lg-4  col-md-4 text-lg-start text-md-start text-sm-center text-center sub-head">
                  <h3 className="fw-bold">
                    <div className="d-flex align-items-center justify-content-center justify-content-md-start ">
                      <div className="pe-2">
                        <i
                          className="bi bi-check-circle-fill"
                          style={{ fontSize: "x-large" }}
                        ></i>
                      </div>
                      100% No Win, No Fee*
                    </div>
                  </h3>
                  <p className="text-lg-start text-md-start text-sm-center text-center mb-2 mb-sm-3">
                    If you qualify for a claim we’ll work with you on a
                    no-win-no-fee basis so you only pay for our services if your
                    claim is successful!
                  </p>
                </div>
                <div className="col-lg-4  col-md-4 text-lg-start text-md-start text-sm-center text-center sub-head">
                  <h3 className="fw-bold">
                    <div className="d-flex align-items-center justify-content-center justify-content-md-start ">
                      <div className="pe-2">
                        <i
                          className="bi bi-check-circle-fill"
                          style={{ fontSize: "x-large" }}
                        ></i>
                      </div>
                      Dedicated Professionals
                    </div>
                  </h3>
                  <p className="text-lg-start text-md-start text-sm-center text-center mb-2 mb-sm-3">
                    Our panel of solicitors has worked on thousands of cases so
                    we’re best placed to help you get the compensation you
                    deserve.
                  </p>
                </div>
                <div className="col-lg-4  col-md-4 text-lg-start text-md-start text-sm-center text-center sub-head">
                  <h3 className="fw-bold">
                    <div className="d-flex align-items-center justify-content-center justify-content-md-start ">
                      <div className="pe-2">
                        <i
                          className="bi bi-check-circle-fill"
                          style={{ fontSize: "x-large" }}
                        ></i>
                      </div>
                      Simple &amp; Quick
                    </div>
                  </h3>
                  <p className="text-lg-start text-md-start text-sm-center text-center mb-2 mb-sm-3">
                    Once we have assessed your case simply sit back and let our
                    expert team of Gurus secure your compensation.
                  </p>
                </div>
              </div>
            </div>

            <p className="mb-2 mb-sm-3">
              <strong>
                <em>*Quiz may take a few seconds to load*</em>
              </strong>
            </p>
            <div className="arrow_gif">
              <img src={arrowGif} alt="" />
            </div>
            {children}
          </div>
        </div>
      </section>
      <div className="AD_PI_V4_B_Layout">
        <section className="guarantsection mt-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="fw-bold mb-4 text-center text-lg-start text-md-start text-sm-center">
                  Our 100% No Win, No Fee* Guarantee
                </h2>
                <p className="text-center text-lg-start text-md-start text-sm-center">
                  Injury cases are conducted on a No Win, No Fee* basis. With no
                  financial risk to you, we can help you receive the
                  compensation you deserve. Our panel of personal injury
                  solicitors will take out an insurance policy on your behalf
                  before starting your case to cover any costs incurred during
                  the personal injury claims process. These costs include court
                  fees, medical evidence, loss of earnings and other expenses.
                  You will not face any hidden charges or fees for payments
                  incurred during the compensation claim process. If you or a
                  loved one have suffered an injury or illness in the last three
                  years that you believe was someone else’s fault, contact our
                  panel of personal injury solicitors to discuss the amount of
                  compensation you could be entitled to.
                </p>
              </div>
              <div className="col-lg-6 img-wrap">
                <img src={NoFeeGuaranteeImg} alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="pt-4 pb-4 sectionreview">
          <div className="container">
            <div
              className="shadow-0 p-4 review-box rounded-3"
              // style={{ border: "0.5px #dddddd solid" }}
            >
              <div className="d-lg-flex d-md-flex d-sm-flex align-items-end  text-center">
                {/* <div className="pe-4">
                  {" "}
                  <img
                    height={50}
                    className="trustlogo"
                    src={TPlogo}
                    alt=""
                  />{" "}
                </div> */}
                <div className="d-flex align-items-center justify-content-center">
                  {/* <div>
                    <div className="text1 mb-2 text-start"> Reviews 2</div>
                    <img className="reviewstar" src={startsImg} alt="" />{" "}
                  </div>
                  <div className="ps-2 text2 text1 ">5.0</div> */}
                  <section className="">
                    <div
                      className="review-header"
                      data-service-review-rating={5}
                    >
                      <div className="rating-star-div">
                        <img
                          alt="Rated 5 out of 5 stars"
                          src="https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-5.svg"
                        />
                      </div>
                      <div className="date-div">
                        <time
                          dateTime="2022-10-07T11:33:59.000Z"
                          className
                          data-service-review-date-time-ago="true"
                          title="Friday, October 7, 2022 at 05:03:59 PM"
                        >
                          Oct 7, 2022
                        </time>
                      </div>
                    </div>
                    <div className="text-start">
                      <a href="" className="">
                        <h2 className="mt-4">Totally recommend!</h2>
                      </a>
                      <p className="">
                        I had a fantastic experience with Tim from Compensation
                        Gurus. He was professional and helped me understand the
                        process and what to expect. I’m glad I contacted them as
                        I had no idea where to start after my accident. They
                        made connecting with a solicitor an easy and smooth
                        experience for me.
                      </p>
                      <p className="mt-4 mb-0">
                        <b weight="heavy">Date of experience{/* */}:</b> {/* */}
                        October 03, 2022
                      </p>
                    </div>
                  </section>
                </div>
              </div>
            </div>
            <div>
              <div></div>
            </div>
          </div>
        </section>
        <Faq />
      </div>
    </>
  );
};

export default AD_PI_V4_B_Layout;
