import React from "react";
import { Helmet } from "react-helmet";
import logo from "../../../../assets/AD_PI_CC_V5/img/logo1.png";

function Header() {
  return (
    <>
      <Helmet>
        <script
          src="//b-js.ringba.com/CAe2cad3c1157d424c9be5d56134af617b"
          async
        ></script>
      </Helmet>

      <header className="d-block d-md-block p-2 bg-white">
        <div className="container text-center">
          <img src={logo} className="logo_1" />
        </div>
      </header>
    </>
  );
}

export default Header;
