import React from "react";
import { useFormContext } from "react-hook-form";
import arrow from "../../../../assets/AD_PI_CC_V5/img/arrow.png";
import safe from "../../../../assets/AD_PI_CC_V5/img/ico-safe.png";

const RadioOption = ({
  label,
  value,
  name,
  next,
  className = "",
  ...props
}) => {
  const { register } = useFormContext();

  return (
    <label className={`icon_option  text-white fw-bold ${className}`}>
      <input
        type="radio"
        className={`radio-button custom-radio-button next-but`}
        name={name}
        ref={register}
        data-value={label}
        defaultValue={value}
        onClick={next}
        {...props}
      />
      {label}
    </label>
  );
};

function RadioQuestion({
  question,
  index,
  next = () => {},
  prev = null,
  name = null,
}) {
  return (
    <div id={`slide${index + 1}`}>
      <div className="col-lg-12 col-md-12 form_cont text-center p-3">
        <div className="my-3">
          <label className={`fw-bold mb-1 qstn_1`}>{question.question}</label>

          {question.answers.map((answer, i) => (
            <RadioOption
              key={answer.label}
              label={answer.label}
              value={answer.value}
              name={name ?? `question_${question.id}`}
              next={next}
              className={i != 0 ? "mt-3" : ""}
            />
          ))}
        </div>

        {!!prev && (
          <a className="back_btn" onClick={prev}>
            <img src={arrow} alt="previous arrow" />
            &nbsp;Previous
          </a>
        )}
      </div>

      <div className="text-center safe-text">
        <img src={safe} className="safe-text mb-1" alt="padlock icon" />{" "}
        <span>Safe | Secure | Privacy Protected</span>
      </div>
    </div>
  );
}

export default RadioQuestion;
