import React, { useEffect, useRef, useState } from "react";
import ChatBotBasicDetails from "./partials/ChatBotBasicDetails";
import ChatBotContactDetails from "./partials/ChatBotContactDetails";
import "../../../../assets/AD_PI_V7/chat-bot/scss/main.scss";
import avatarImage from "../../../../assets/AD_PI_V7/img/avatar.png";
import avatarImage2 from "../../../../assets/AD_PI_V7/img/avatar2.png";
import loaderGif from "../../../../assets/AD_PI_V7/img/loader.gif";

const ChatBot = ({
  className,
  validation,
  validationMsg,
  trigger,
  slideChange,
  prevSlide,
  state,
  setError,
  textHeading2,
  textHeading,
  textHeading3,
  textHeading1,
  clearErrors,
  getValues,
  formSubmit,
}) => {
  const [showLoaderGif, setShowLoaderGif] = useState(true);
  const [showLoaderGif1, setShowLoaderGif1] = useState(false);
  const [showLoaderGif2, setShowLoaderGif2] = useState(false);
  const [showLoaderGif3, setShowLoaderGif3] = useState(false);
  const [showLoaderGif4, setShowLoaderGif4] = useState(false);
  const [fullName, setFullName] = useState(undefined);
  const contactRef = useRef(undefined);
  const [submitClicked, setSubmitClicked] = useState(false);

  const PhoneValue = getValues("txtPhone");
  const EmailValue = getValues("txtEmail");

  useEffect(() => {
    const showLoadersSequentially = async (afterName = false) => {
      if (afterName) {
        // window.scrollTo({
        //   top: 0,
        //   behavior: "smooth",
        // });
        contactRef.current &&
          contactRef.current.scrollIntoView({
            behavior: "smooth",
          });
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setShowLoaderGif4(true);
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 1500));
      setShowLoaderGif(false);

      await new Promise((resolve) => setTimeout(resolve, 1500));
      setShowLoaderGif1(true);

      await new Promise((resolve) => setTimeout(resolve, 1500));
      setShowLoaderGif2(true);

      await new Promise((resolve) => setTimeout(resolve, 1500));
      setShowLoaderGif3(true);
    };

    if (state?.showSlide6 == "show") {
      if (fullName) {
        showLoadersSequentially(true);
        return;
      }
      setTimeout(() => {
        showLoadersSequentially();
      }, [2500]); // wait for loader to finish
    }
  }, [state?.showSlide6, fullName]);

  return (
    <>
      <div id="chat-bot" className={className}>
        <div className="body-inner">
          <section className="chat-section">
            <div className="container">
              <div className=" mt-0 mb-lg-5 mb-md-5 mb-sm-3 mb-3">
                <div className="chat-wrap">
                  <div className="mb-1 support-online py-3 text-center">
                    <span className="circle pulse green me-1" /> Maria is online{" "}
                  </div>
                  <div className="chat-box-inner">
                    <div className="row-chart-message mb-4 ">
                      {/* <div style={{ display: !fullName ? "block" : "none" }}> */}
                      <div className="chater-prof" id="slider1">
                        <div>
                          <div className="supporter-dp">
                            <img src={avatarImage} alt="" />
                          </div>
                        </div>
                        <div>
                          {!!showLoaderGif && (
                            <div className="msg-loader loader1">
                              <div className="text-msg">
                                {" "}
                                <img src={loaderGif} alt="" />
                              </div>
                            </div>
                          )}
                          {!showLoaderGif && (
                            <div className="mesg1">
                              <span className="text-msg text-message-item slide-top">
                                {" "}
                                Congratulations! 🤩🤩🥳🥳🥳{" "}
                              </span>
                            </div>
                          )}
                          {!showLoaderGif && !showLoaderGif1 && (
                            <div className="msg-loader loader2">
                              <div className="text-msg">
                                {" "}
                                <img src={loaderGif} alt="" />
                              </div>
                            </div>
                          )}
                          {!!showLoaderGif1 && (
                            <div className=" mesg2">
                              <div className="text-msg">
                                <span className="action-btn   text-message-item slide-top">
                                  Great News!
                                </span>
                                {/* <span  class="action-btn  text-message-item   msg01 " data-type="No"> No</span> */}
                              </div>
                            </div>
                          )}
                          {!!showLoaderGif1 && !showLoaderGif2 && (
                            <div className="msg-loader loader02">
                              <div className="text-msg">
                                {" "}
                                <img src={loaderGif} alt="" />
                              </div>
                            </div>
                          )}
                          {!!showLoaderGif2 && (
                            <div className=" mesg02">
                              <div className="text-msg">
                                <span className="action-btn   text-message-item slide-top">
                                  It appears you may have a successful
                                  compensation claim.
                                </span>
                              </div>
                            </div>
                          )}
                          {!!showLoaderGif2 && !showLoaderGif3 && (
                            <div className="msg-loader loader02a">
                              <div className="text-msg">
                                {" "}
                                <img src={loaderGif} alt="" />
                              </div>
                            </div>
                          )}
                          {!!showLoaderGif3 && (
                            <>
                              <div className=" mesg02a">
                                <div className="text-msg">
                                  <span className="action-btn   text-message-item slide-top">
                                    Please provide some of your details so our
                                    agent can assist you with this claim. Help
                                    us with your Name
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: !fullName ? "block" : "none",
                                }}
                              >
                                <ChatBotBasicDetails
                                  className={className}
                                  validation={validation}
                                  validationMsg={validationMsg}
                                  trigger={trigger}
                                  slideChange={slideChange}
                                  prevSlide={prevSlide}
                                  getValues={getValues}
                                  setError={setError}
                                  clearErrors={clearErrors}
                                  textHeading1={textHeading1}
                                  textHeading2={textHeading2}
                                  textHeading3={textHeading3}
                                  textHeading={textHeading}
                                  formSubmit={formSubmit}
                                  setFullName={setFullName}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {/* </div> */}

                      <div
                        style={{
                          display: !!fullName ? "block" : "none",
                        }}
                      >
                        <div
                          id="slider4"
                          className="client-replay my-3 "
                          ref={contactRef}
                        >
                          <div className="client-replay-vpx">
                            <div className="dp-client">
                              <img src={avatarImage2} alt="" />
                            </div>
                            <div className="mesg8 sel-option">
                              <span
                                className="action-btn  text-message-item rounded answ-bnt"
                                id="noMedico2"
                              >
                                {fullName}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="chater-prof" id="slider5">
                          <div>
                            <div className="supporter-dp">
                              <img src={avatarImage} alt="" />
                            </div>
                          </div>
                          <div>
                            {!showLoaderGif4 && (
                              <div className="msg-loader loader6">
                                <div className="text-msg">
                                  {" "}
                                  <img src={loaderGif} alt="" />
                                </div>
                              </div>
                            )}
                            {!!showLoaderGif4 && (
                              <div className=" mesg9">
                                <div className="text-msg text-message-item slide-top">
                                  <div>
                                    Thanks for that. <br />
                                    Kindly provide your current phone number and
                                    Email Address to ensure effective
                                    communication.
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: submitClicked ? "none" : "block",
                                  }}
                                >
                                  <ChatBotContactDetails
                                    className={className}
                                    validation={validation}
                                    validationMsg={validationMsg}
                                    trigger={trigger}
                                    slideChange={slideChange}
                                    prevSlide={prevSlide}
                                    getValues={getValues}
                                    setError={setError}
                                    clearErrors={clearErrors}
                                    textHeading1={textHeading1}
                                    textHeading2={textHeading2}
                                    textHeading3={textHeading3}
                                    textHeading={textHeading}
                                    formSubmit={formSubmit}
                                    setSubmitClicked={setSubmitClicked}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {submitClicked && (
                          <>
                            <div id="slider4" className="client-replay my-3 ">
                              <div className="client-replay-vpx">
                                <div className="dp-client">
                                  <img src={avatarImage2} alt="" />
                                </div>
                                <div className="mesg8 sel-option">
                                  <span
                                    className="action-btn  text-message-item rounded answ-bnt"
                                    id="noMedico2"
                                  >
                                    {PhoneValue ?? ""}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div id="slider4" className="client-replay my-3 ">
                              <div className="client-replay-vpx">
                                <div className="dp-client">
                                  <img src={avatarImage2} alt="" />
                                </div>
                                <div className="mesg8 sel-option">
                                  <span
                                    className="action-btn  text-message-item rounded answ-bnt"
                                    id="noMedico2"
                                  >
                                    {EmailValue ?? ""}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default ChatBot;
