import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";
import SelectBox from "../../../UI/SelectBox";
import { Salutation } from "../../../../Constants/Questions";
import ConsentSection from "./ConsentSection";
const BasicDetails = ({
  className,
  validation,
  validationMsg,
  trigger,
  slideChange,
  prevSlide,
  setError,
  textHeading2,
  textHeading3,
  clearErrors,
  getValues,
}) => {
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let txtLNameResult;
    let txtFNameResult;
    const values = getValues();
    const formDataT = values;
    const txtFirstName = formDataT.txtFName.trim();
    const txtLastName = formDataT.txtLName.trim();
    console.log(txtFirstName.length);

    var lstSalutationResult = await trigger("lstSalutation");
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
      document.getElementById("txtLName").focus();
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }

    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      return false;
    }

    if (!txtFNameResult || !txtLNameResult) {
      errorFlag = 1;
    }

    if (txtFirstName.length < 3) {
      setError("txtFName", {
        type: "manual",
        message: "Your First Name is Invalid. Please Recheck",
      });
      document.getElementById("txtFName").focus();
      errorFlag = 1;
      return false;
    }

    if (txtLastName.length < 3) {
      setError("txtLName", {
        type: "manual",
        message: "Your Last Name is Invalid. Please Recheck",
      });
      document.getElementById("txtLName").focus();
      errorFlag = 1;
      return false;
    }

    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  return (
    <>
      <div id="slide8" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center px-3 py-0 py-sm-3">
          <div className="my-3">
            {/* <p className="mb-0 fs-5 fw-bold para">{textHeading1}</p> */}
            <label className="mb-1  fw-bold qstn_1">{textHeading2}</label>
            <h5>{textHeading3}</h5>
            <div className="form-group mb-3">
              <SelectBox
                className="form-select form-control p-3"
                OptionValue={Salutation}
                name="lstSalutation"
                id="lstSalutation"
                onChange={() => clearErrors("lstSalutation")}
                clearErrors={clearErrors}
                myRef={validation({ required: "Please Select Title" })}
                validationMsg={
                  validationMsg.lstSalutation &&
                  validationMsg.lstSalutation.message
                }
              ></SelectBox>
            </div>
            <div className="form-group mb-3">
              <TextField
                type="text"
                className="form-control p-3"
                placeholder="First Name"
                name="txtFName"
                id="txtFName"
                onBlur={namevalidate}
                validation={validation({
                  required: "Please Enter First Name",
                  minLength: {
                    value: 3,
                    message: "Please Enter Valid First Name",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Name is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.txtFName && validationMsg.txtFName.message
                }
              ></TextField>
            </div>
            <div className="form-group">
              <TextField
                type="text"
                className="form-control p-3"
                placeholder="Surname"
                name="txtLName"
                id="txtLName"
                onBlur={namevalidate}
                validation={validation({
                  required: "Please Enter Surname",
                  minLength: {
                    value: 2,
                    message: "Please Enter Valid Surname",
                  },
                  pattern: {
                    value: /^[a-zA-Z\s]+$/,
                    message: "Your Surname is Invalid. Please Recheck",
                  },
                })}
                validationMsg={
                  validationMsg.txtLName && validationMsg.txtLName.message
                }
              ></TextField>
            </div>
          </div>
          <InputButton
            name="basic_details"
            className="next-but btn my-bt flasher2 mb-3 text-white fw-bold rounded"
            value="Continue >>"
            btnType="button"
            onClick={nextSlide}
          />
          <a
            name="type_of_injury"
            className="back_btn sctop1"
            onClick={prevSlide}
          >
            <img src="assets/AD_PI_V3/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text p-3">
          <img
            src="assets/AD_PI_V3/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
      </div>
    </>
  );
};
export default BasicDetails;
