export const Questions = [
  {
    id: 1,
    name: "were_you_injured",
    question: "Were you injured in the last 3 years?",
    tag: "Injured in 3 years?",
    answers: [
      {
        label: "Yes",
        value: 1,
      },
      {
        label: "No",
        value: 2,
        isUnacceptable: true,
      },
    ],
  },
  {
    id: 2,
    name: "working_with_attorney",
    question: "Are you already working with a solicitor?",
    tag: "Working with a solicitor",
    answers: [
      {
        label: "No",
        value: 4,
      },
      {
        label: "Yes",
        value: 3,
        isUnacceptable: true,
      },
    ],
  },
  {
    id: 3,
    name: "how_did_you_injured",
    question: "How did you get injured?",
    tag: "How did you get injured?",
    answers: [
      {
        label: "Accident At Work",
        value: 5,
        conversionValue: 100,
      },
      {
        label: "Medical Procedure",
        value: 6,
        conversionValue: null,
      },
      {
        label: "Slip Trip or Fall",
        value: 7,
        conversionValue: null,
      },
      {
        label: "Road Traffic Accident",
        value: 8,
        conversionValue: 10,
      },
      {
        label: "Accident In a Private Place (Shop, Bar, Restaurant etc.)",
        value: 23,
        conversionValue: 50,
      },
      {
        label: "Accident In a Public Place (Park, Pavement etc.)",
        value: 24,
        conversionValue: 10,
      },
      {
        label: "Industrial Illness",
        value: 26,
        conversionValue: 33,
      },
      {
        label: "Criminal Injury",
        value: 25,
        conversionValue: 25,
      },
      {
        label: "Other",
        value: 27,
        conversionValue: 1,
      },
    ],
  },
  {
    id: 4,
    name: "type_of_injury",
    question: "Type of Injury?",
    tag: "Type of Injury?",
    answers: [
      {
        label: "Head",
        value: 9,
      },
      {
        label: "Brain",
        value: 10,
      },
      {
        label: "Upper body",
        value: 11,
      },
      {
        label: "Lower body",
        value: 12,
      },
      {
        label: "Internal Organs",
        value: 13,
      },
      {
        label: "Multiple Body Parts",
        value: 14,
      },
      {
        label: "Psychological",
        value: 15,
      },
      {
        label: "Others",
        value: 16,
      },
    ],
  },
  {
    id: 6,
    name: "medical_attention",
    question: "Did you receive medical attention?",
    tag: "Medical attention",
    answers: [
      {
        label: "Yes",
        value: 21,
      },
      {
        label: "No",
        value: 22,
      },
    ],
  },
];

export const getQuestion = (name) => {
  return Questions.find((question) => question.name === name);
};

export const getAnswer = (name, value) => {
  const question = getQuestion(name);

  return question?.answers?.find((answer) => answer.value == value);
};

export const getValue = (name, label) => {
  const answer = getAnswer(name, label);

  return answer?.value;
};

export const isUnacceptable = (name, value) => {
  const answer = getAnswer(name, value);

  return answer?.isUnacceptable;
};
