import { Api, CommonPhoneValidation } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const usePhoneValidation = () => {
  const phoneValidation = async (phone) => {
    const response = await CommonPhoneValidation.post("/api/validate", {
    "ProjectCode":EnvConstants.AppAdtopiaUPID,
    "Environment": EnvConstants.AppEnv,
    "Phone": phone,
    });
    return response;
    // const getValidation = await Api.get(
    //   "api/v1/get-phone-validation?phone=" + phone,
    //   {}
    // );
    // return getValidation;
  };
  return { phoneValidation };
};
