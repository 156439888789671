import React,{useState,useEffect} from 'react';
import RadioButtonQuest from '../../../UI/RadioButtonQuest';
import Modal from 'react-bootstrap/Modal';
import RadioButtonFlpQuest from '../../../UI/RadioButtonFlpQuest';

const QuestionnaireCheckboxModal = ({questArr,popDisplay,onModalClick}) => {
    const countString = {
        0:'A',
        1:'B',
        2:'C',
        3:'D',
        4:'E',
        5:'F',
        3:'D'
    }
    const handleClose = () => setShow(false);
    
    const questAnswers = questArr.answers.map((answer,index) => {
        return (
            <>
                <RadioButtonFlpQuest  
                    key={answer.value} 
                    className="radio"
                    labelName={answer.label}
                    labelClassName="br radioa"
                    labelCountString={countString[index]}
                    value={answer.value}
                    name={questArr.questionId}
                    id={answer.value}
                    onLabelClick={onModalClick}
                    onClick={handleClose}
                />
            </>)
    })

    const [show, setShow] = useState(false);


    useEffect(()=>{
        setShow(popDisplay);
    },[popDisplay])
    return (
 
            <Modal show={show} onHide={handleClose} dialogClassName="modalReact">
                <Modal.Header closeButton>
                <Modal.Title>{ questArr.question }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalform form-part">
                        <form>
                            <div className="form-group">
                                {questAnswers}
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
       
    )
}

export default QuestionnaireCheckboxModal;