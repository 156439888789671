import React, { useEffect, useState } from "react";
import Header from "../../Includes/Layouts/AD_PI_V7/Header";
import Footer from "../../Includes/Layouts/AD_PI_V7/Footer";
import "../../../assets/AD_PI_V7/css/AD_PI_V7.scss";
import "../../../assets/AD_PI_V7/css/animate.css";
import FormAD_PI_V7 from "../../Forms/FormAD_PI_V7";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import arrowGif from "../../../assets/AD_PI_V7/img/fc6c95b9-o-arrow.gif";

const AD_PI_V7 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "AD_PI_V7",
    "AD_PI_V7"
  );

  const [chatBoatPage, setChatBoatPage] = useState(false);

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="AD_PI_V7" />
      <div className="AD_PI_V7" id="AD_PI_V7">
        <Header />
        <section className="py-0 py-md-2 SECT ">
          <div className="container p-0">
            <div className="form_part bg-white px-0 px-md-2 py-0 py-md-3">
              {!chatBoatPage && (
                <>
                  <h1 className="d-none d-sm-block d-md-block d-lg-block text-center fw-bold">
                    Take This 30 Second Assessment To Help Maximise Your
                    Potential Compensation
                  </h1>
                  <h4 className="d-none d-sm-block d-md-block d-lg-block text-center">
                    We offer a FREE no obligation, no win-no fee consultation.
                    Get started below by answering a few questions. It's quick
                    and easy.
                  </h4>
                  <div className="sm-device-txt">
                    <h1 className="d-block d-sm-none d-md-none d-lg-none text-center text-white fw-bold">
                      Take This 30 Second Assessment To Help Maximise Your
                      Potential Compensation
                    </h1>
                    <h2 className="d-block d-sm-none d-md-none d-lg-none text-center">
                      We offer a FREE no obligation, no win-no fee consultation.
                      Get started below by answering a few questions. It's quick
                      and easy.
                    </h2>
                  </div>
                  <p>
                    <strong>
                      <em>*Quiz may take a few seconds to load*</em>
                    </strong>
                  </p>
                  <div className="arrow_gif">
                    <img src={arrowGif} alt="" />
                  </div>
                </>
              )}

              <FormAD_PI_V7
                splitName={DynamicRouteSplitName}
                nextPage={DynamicRouteNextPage}
                setChatBoatPage={setChatBoatPage}
                chatBoatPage={chatBoatPage}
              />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default AD_PI_V7;
