import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CookiePopUp = ({ cookieShow, setCookieShow }) => {
  return (
    <>
      <Modal
        size="lg"
        backdrop="static"
        keyboard={false}
        show={cookieShow}
        onHide={() => setCookieShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Cookie Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><b>What Are Cookies</b></p>

          <p>
            As is common practice with almost all professional websites this site uses cookies, which are tiny files
            that are downloaded to your computer, to improve your experience. This page describes what information they
            gather, how we use it and why we sometimes need to store these cookies. We will also share how you can
            prevent these cookies from being stored however this may downgrade or &lsquo;break&rsquo; certain elements
            of the sites functionality.
          </p>

          <p>For more general information on cookies see aboutcookies.org.uk</p>

          <p><b>How We Use Cookies</b></p>

          <p>
            We use cookies for a variety of reasons detailed below. Unfortunately in most cases there are no industry
            standard options for disabling cookies without completely disabling the functionality and features they
            add to this site. It is recommended that you leave on all cookies if you are not sure whether you need them
            or not in case they are used to provide a service that you use.
          </p>

          <p><b>Disabling Cookies</b></p>

          <p>
            You can prevent the setting of cookies by adjusting the settings on your browser (see your browser Help
            for how to do this). Be aware that disabling cookies will affect the functionality of this and many other
            websites that you visit. Disabling cookies will usually result in also disabling certain functionality and
            features of this site. Therefore it is recommended that you do not disable cookies.
          </p>

          <p><b>The Cookies We Set</b></p>

          <ul>
            <li>
              Forms related cookies: when you submit data through a form such as our 'contact us' form cookies may be set
              to remember your user details for future correspondence.
            </li>
          </ul>

          <p></p>

          <p><b>Third Party Cookies</b></p>

          <p></p>

          <p>
            In some special cases we also use cookies provided by trusted third parties. The following section details
            which third party cookies you might encounter through this site.
          </p>

          <ul>
            <li>
              This site uses Google Analytics which is one of the most widespread and trusted analytics solution on the
              web for helping us to understand how you use the site and ways that we can improve your experience. These
              cookies may track things such as how long you spend on the site and the pages that you visit so we can
              continue to produce engaging content. For more information on Google Analytics cookies, see the official
              Google Analytics page.
            </li>

            <li>From time to time we test new features and make subtle changes to the way that the site is delivered. When
              we are still testing new features these cookies may be used to ensure that you receive a consistent
              experience whilst on the site whilst ensuring we understand which optimisations our users appreciate the most.
            </li>
          </ul>
          <p></p>

          <p><b>More Information</b></p>

          <p>If you are still looking for more information then you can contact us:{' '}
            <a href="mailto:hello@accelldigital.co.uk">hello@accelldigital.co.uk</a>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setCookieShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CookiePopUp;
