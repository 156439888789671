import React from 'react';
import RadioButtonFlpQuest from '../../../UI/RadioButtonFlpQuest';

const QuestionnaireFlpWrapper = ({questArr,labelclicked}) => {
    const countString = {
        0:'A',
        1:'B',
        2:'C'
    }
    const questAnswers = questArr.answers.map((answer,index) => {
        return (
            <>
                <RadioButtonFlpQuest  
                    key={answer.value}
                    className="radio"
                    labelName={answer.label}
                    labelClassName="br radioa l_g"
                    labelCountString={countString[index]}
                    value={answer.value}
                    name={questArr.questionId}
                    id={answer.value}
                    onLabelClick={labelclicked}
                />
            </>
            )
    })
    return (
        <>
            <h4 className="flp-qtext">
                {questArr.question}
            </h4>
            <div className="form-group">
                <div className="" id="question_group_60" >
                    {questAnswers}
                </div>
            </div>
        </>
    )
}

export default QuestionnaireFlpWrapper;