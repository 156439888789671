import React, { useState } from "react";

const ProgressBars = ({ value }) => {
  return (
    <>
      <div className="progress m-0 mx-2 mt-4 m-md-4" style={{ height: 20 }}>
        <div
          className="progress-bar bg-grn"
          role="progressbar"
          aria-label="Example 10px high"
          style={{ width: `${value}%` }}
          aria-valuenow={value}
          aria-valuemin={0}
          aria-valuemax={100}
          id="prog_1"
        />
      </div>
    </>
  );
};
export default ProgressBars;
