import React from "react";
import FbViewPixel from "../../../../Utility/FbViewPixel";

const Header = () => {
  return (
    <>
      <FbViewPixel />

      <div style={{ backgroundColor: "#015882" }}>
        <p
          className="mb-0 text-white text-center py-2"
          style={{ fontSize: "14px" }}
        >
          Claims Gurus is a trading style of Accell Digital which is a
          registered claims management company, and we receive payments from our
          panel solicitors for our service.
        </p>
      </div>

      <header className="p-1 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <img
                src="assets/AD_PI_V3/img/logonew.png"
                alt=""
                className="logo p-1"
              />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
