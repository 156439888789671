import React from "react";
import Header from "../../Includes/Layouts/AD_PI_V4_B/Header";
import Footer from "../../Includes/Layouts/AD_PI_V4_B/Footer";
import "../../../assets/AD_PI_V4_B/css/AD_PI_V4_B.scss";
import "../../../assets/AD_PI_V4_B/css/animate.css";
import FormAD_PI_V4_B from "../../Forms/FormAD_PI_V4_B";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";
import AD_PI_V4_B_Layout from "../../Includes/Layouts/AD_PI_V4_B/AD_PI_V4_B_Layout";

const AD_PI_V4_B = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "AD_PI_V4_B",
    "AD_PI_V4_B"
  );
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="AD_PI_V4_B" />
      <div className="AD_PI_V4_B" id="AD_PI_V4_B">
        <Header />
        <AD_PI_V4_B_Layout>
          <FormAD_PI_V4_B
            splitName={DynamicRouteSplitName}
            nextPage={DynamicRouteNextPage}
          />
        </AD_PI_V4_B_Layout>

        <Footer />
      </div>
    </>
  );
};

export default AD_PI_V4_B;
