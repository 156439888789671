import { Api } from "../api/Api";


export const useVisitorAnswer = () => {

  const saveAnswer = async (questionaire_id, answer_id,visitor_data) => {
    const response = await Api.post("api/v1/visitor-answer", {
      questionaire_id,
      answer_id,
      visitor_data,
    });
    return response;
  };
  return { saveAnswer };
};
