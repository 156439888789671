import React, { useState, useContext } from "react";
import RadioButton from "../../../UI/RadioButton";
import ConsentSection from "./ConsentSection";
import Modal from "react-bootstrap/Modal";
import loaderGif from "../../../../assets/AD_PI_V7/img/new-loader.gif";

const MedicalAttention = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
  setChatBoatPage,
}) => {
  const [active21, setActive21] = useState("");
  const [active22, setActive22] = useState("");
  const [show, setShow] = useState(false);
  const [showTwo, setShowTwo] = useState(false);
  const [modalClass1, SetModalClass1] = useState("hide");
  const [modalClass2, SetModalClass2] = useState("hide");

  const nextSlide = async (e) => {
    if (e.target.value == 21) {
      setActive21("active");
    } else {
      setActive21("");
    }

    if (e.target.value == 22) {
      // const saveAns = await saveAnswer(2, e.target.value, visitorParameters.visitor_parameters);

      setActive22("active");
    } else {
      setActive22("");
    }

    setShow(true);

    setTimeout(() => {
      setShow(false);
      SetModalClass1("show");
      setShowTwo(true);
      setChatBoatPage(true);

      setTimeout(() => {
        UpdateSlide1();
      }, 2500);
      // setTimeout(() => {
      //   UpdateSlide2();
      // }, 3500);
      const UpdateSlide1 = () => {
        SetModalClass2("show");
      };
      // const UpdateSlide2 = () => {
      //   document.body.classList.remove("modal-open");
      //   SetModalClass1("hide");
      //   SetModalClass2("hide");
      //   setShowTwo(false);
      //   //slideChange(e);
      // };
    }, 2500);
    slideChange(e);
  };

  return (
    <>
      <div id="slide1" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center  p-3">
          <div className="my-3">
            <label className="fw-bold mb-1 qstn_1">{textHeading}</label>
            <RadioButton
              value="21"
              name="medical_attention"
              className="radio-button custom-radio-button"
              labelName="Yes"
              labelClassName={`icon_option  text-white fw-bold  mb-3 ${active21}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="22"
              name="medical_attention"
              className="radio-button custom-radio-button  next-but"
              labelName="No"
              labelClassName={`icon_option text-white fw-bold nopad ${active22}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <a
            name="were_you_injured"
            className="back_btn sctop1"
            onClick={prevSlide}
          >
            <img src="assets/AD_PI_V3/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text p-3">
          <img
            src="assets/AD_PI_V3/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
      </div>

      <Modal
        size="lg"
        className="custom_modal text-center "
        show={show}
        centered
        // contentClassName="nobg"
      >
        <Modal.Body className="bgno">
          {/* <img src="assets/AD_PI_V3/img/review1.gif" /> */}
          <img src={loaderGif} />
        </Modal.Body>
      </Modal>

      {/* <Modal
        size="lg"
        className="custom_modal text-center"
        show={showTwo}
        centered
        id="analayzeModal"
      >
        <Modal.Body>
          <div className="py-5">
            <div
              id="slide6nxt"
              className={`col-12 slide-pad  text-center ${modalClass1}`}
            >
              <h2>Great News!</h2>
            </div>
            <div
              id="slide62nxt"
              className={`col-12 slide-pad text-center slide6nxt animated fadeInUp ${modalClass2}`}
            >
              <h2>It appears you may have a successful compensation claim.</h2>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};
export default MedicalAttention;
