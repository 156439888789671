import React, { useState } from "react";
import PrivacyPopUp from "./PrivacyPopUp";
import CookiePopUp from "./CookiePopUp";

const Footer = () => {
  const [privacyShow, setPrivacyShow] = useState(false);
  const [cookieShow, setCookieShow] = useState(false);

  return (
    <>
      <footer>
        <div className="container">
          <div className="row px-3">
            *Our cases are conducted on a No Win, No Fee* basis. Our clients
            typically pay 25% plus VAT of any damages they are awarded.
            <br />
            <br />
            Our panel of personal injury solicitors will take out an insurance
            policy on your behalf before starting your case to cover any costs
            incurred during the personal injury claims process. These costs
            include court fees, medical evidence, loss of earnings and other
            expenses. You will not face any hidden charges or fees for payments
            incurred during the compensation claim process.
            <br />
            <br />
            You may be charged a fee if you cancel after the cooling-off period
            or fail to cooperate with the solicitor you signed up for. The panel
            solicitor will provide full details of any fees you may be liable
            for before you enter into a contract.
            <br />
            <br />
            Claims Gurus is a Trading Name under Accell Digital Limited.
            Registered Address: Accell Digital, 7 Bell Yard, London WC2A 2JR.
            Company Number: 13539012. VAT Number: 389529826. ICO Number:
            ZB239690.
            <br />
            <br />
            Accell Digital is Authorised and Regulated by The Financial Conduct
            Authority in respect of regulated claims management activities. Firm
            Reference Number FRN964366.
            <br />
            <br />
            Claims Gurus is a claims management company that receives payments
            from partnered law firms for our service
            <br />
            <br />
            Contact: 020 7126 8245 - hello@compensationgurus.co.uk
            <div className="flex-row p-0">
              <ul>
                <li>
                  <a onClick={() => setPrivacyShow(true)}>Privacy Policy</a>
                </li>
                <li>
                  <a onClick={() => setCookieShow(true)}>Cookie Policy</a>
                </li>
                <li>
                  <a href="/complaints-procedure" target="_blank">
                    Complaints Procedure
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <PrivacyPopUp privacyShow={privacyShow} setPrivacyShow={setPrivacyShow} />
      <CookiePopUp cookieShow={cookieShow} setCookieShow={setCookieShow} />
    </>
  );
};

export default Footer;
