import React from "react";
import { useFormContext } from "react-hook-form";
import { getAnswer, getQuestion } from "../../../../Constants/QuestionsV5";
import GtmDataLayer from "../../GtmDataLayer";
import RadioQuestion from "./RadioQuestion";

const HowDidYouGetInjured = ({ name, index, next, prev }) => {
  const { trigger, watch } = useFormContext();
  const question = getQuestion(name);

  const proceed = (e) => {
    trigger(name).then((isValid) => {
      if (!isValid) return;

      next();

      const value = watch(name);
      const answer = getAnswer(name, value);

      // localStorage.setItem("conversionValue", answer.conversionValue);
      // localStorage.setItem("typeOfInjury", answer.label);

      GtmDataLayer({
        question: question?.tag,
        answer: value,
        // optionvalue: value,
        // conversionvalue: answer?.conversionValue,
        // injurytype: answer.label,
      });
    });
  };

  return (
    <>
      <RadioQuestion
        question={question}
        name={name}
        index={index}
        next={proceed}
        prev={prev}
      />
    </>
  );
};

export default HowDidYouGetInjured;
