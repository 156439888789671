import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useHistory } from "react-router-dom";
import ProgressBar from "../Includes/Layouts/AD_PI_V6_1/ProgressBar";
import HowDidyouGetInjured from "../Includes/Layouts/AD_PI_V6_1/HowDidyouGetInjured";
import WereYouInjured from "../Includes/Layouts/AD_PI_V6_1/WereYouInjured";
import WorkingWithAttorney from "../Includes/Layouts/AD_PI_V6_1/WorkingWithAttorney";
import BasicDetails from "../Includes/Layouts/AD_PI_V6_1/BasicDetails";
import ContactDetails from "../Includes/Layouts/AD_PI_V6_1/ContactDetails";
import GtmDataLayer from "../Includes/GtmDataLayer";
import TypeOfInjury from "../Includes/Layouts/AD_PI_V6_1/TypeOfInjury";
import MedicalAttention from "../Includes/Layouts/AD_PI_V6_1/MedicalAttention";
import ChatBot from "../Includes/Layouts/AD_PI_V6_1/ChatBot";

const FormAD_PI_V6_1 = ({ setChatBoatPage, chatBoatPage, ...props }) => {
  const [progress, setProgress] = useState(0);
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [partsSelected] = useState([]);
  const field = "pid";
  const history = useHistory();
  const [gtmValue, setGtmvalue] = useState(null);

  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
  };
  const FormReducer = (state, action) => {
    let answr = action.payload.clickedSlide.dataValue;
    let conversion_value = action.payload.clickedSlide.conversion;

    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "question_0") {
          setProgress(0);
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide",
          };
        }
        if (action.payload.clickedSlide.slide === "were_you_injured") {
          setProgress(50);
          GtmDataLayer({
            question: "Injured in 3 years?",
            answer: answr,
          });
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
            showSlide5: "hide",
          };
          // return {
          //   ...state,
          //   showSlide1: "hide",
          //   showSlide2: "show",
          //   showSlide3: "hide",
          // };
        } else if (
          action.payload.clickedSlide.slide === "working_with_attorney"
        ) {
          setProgress(60);
          GtmDataLayer({
            question: "Working with a solicitor",
            answer: answr,
          });
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
            showSlide6: "hide",
          };
          // return {
          //   ...state,
          //   showSlide2: "hide",
          //   showSlide3: "show",
          //   showSlide4: "hide",
          // };
        } else if (
          action.payload.clickedSlide.slide === "how_did_you_injured"
        ) {
          setProgress(20);

          const typeOfInjury = action.payload.clickedSlide.dataValue;

          localStorage.setItem("conversionValue", conversion_value);
          localStorage.setItem("typeOfInjury", JSON.stringify(typeOfInjury));

          console.log(
            "in reducer : ",
            action.payload.clickedSlide.slide,
            typeOfInjury
          );

          GtmDataLayer({
            question: "How did you get injured?",
            answer: answr,
            conversionvalue: conversion_value,
            optionValue: answr,
            injurytype: typeOfInjury ? typeOfInjury.label : "",
          });

          // return {
          //   ...state,
          //   showSlide3: "hide",
          //   showSlide4: "show",
          //   showSlide5: "hide",
          // };
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
            showSlide3: "hide",
          };
        } else if (action.payload.clickedSlide.slide === "type_of_injury") {
          setProgress(40);
          GtmDataLayer({
            question: "Type of Injury?",
            answer: answr,
          });
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
            showSlide4: "hide",
          };
          // return {
          //   ...state,
          //   showSlide4: "hide",
          //   showSlide5: "show",
          //   showSlide6: "hide",
          // };
        } else if (action.payload.clickedSlide.slide === "medical_attention") {
          setProgress(80);
          GtmDataLayer({
            question: "Medical attention",
            answer: answr,
          });
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
            showSlide7: "hide",
          };
        } else if (action.payload.clickedSlide.slide === "basic_details") {
          setProgress(99);
          // GtmDataLayer({
          //   question: "Legal Name",
          //   answer: "Completed",
          // });
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
            showSlide8: "hide",
          };
        } else if (action.payload.clickedSlide.slide === "") {
          setProgress();
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    document.getElementById("div_form").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
    setClickedSlide({
      slide: e.target.name,
      dataValue: e.target.getAttribute("data-value"),
      conversion: e.target.getAttribute("convert-value"),
    });
  };

  const prevSlide = (e) => {
    document.getElementById("div_form").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });

    setClickedSlide({ slide: e.target.name, dataValue: null });
  };

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const formSubmit = async (redirection) => {
    if (isLoading) return;

    GtmDataLayer({
      question: "Phone, Email",
      answer: "Completed",
      conversionvalue: gtmValue,
    });
    const values = getValues();
    const formData = values;
    console.log(values);
    formData.lstSalutation = values.lstSalutation;
    formData.txtFName = values.txtFName;
    formData.txtLName = values.txtLName;
    formData.txtEmail = values.txtEmail;
    formData.txtPhone = values.txtPhone;
    //Questionnaires
    formData.question_1 = values.were_you_injured;
    formData.question_2 = values.working_with_attorney;
    formData.question_3 = values.how_did_you_injured;
    formData.question_4 = values.type_of_injury;
    formData.question_5 = values.case_type;
    formData.question_6 = values.medical_attention;
    let bodyParts = partsSelected.map((a) => a.id);
    formData.question_7 = bodyParts;

    localStorage.setItem("formData", JSON.stringify(formData));
    localStorage.setItem("uuid", visitorParameters.visitor_parameters.uuid);

    const formSUbmitResult = await saveDataIngestion(
      visitorParameters.visitor_parameters,
      formData,
      "split_form_submit",
      props.splitName ?? "AD_PI_V6.1",
      queryString,
      visitorParameters.data
    );

    setTimeout(() => {
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            history.push(
              "/fbpixel?split_name=" +
                props.splitName +
                "&next_page=" +
                props.nextPage
            );
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/" +
                props.nextPage +
                "?uuid=" +
                visitorParameters.visitor_parameters.uuid +
                "&splitName=" +
                props.splitName
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/" +
              props.nextPage +
              "?uuid=" +
              visitorParameters.visitor_parameters.uuid +
              "&splitName=" +
              props.splitName
          );
        }
      }
    }, [1500]);
  };

  return (
    <>
      {/* <!--  ========= FormSection  Area Start  ========= --> */}
      <div id="div_form">
        <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
        >
          {!chatBoatPage && <ProgressBar value={progress} />}
          {/* <WereYouInjured
            // className={state.showSlide1}
            className={state.showSlide3}
            textHeading="Were you injured in the last 3 years?"
            validation={register}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
          />

          <WorkingWithAttorney
            className={state.showSlide4}
            // className={state.showSlide2}
            textHeading="Are you already working with a solicitor?"
            validation={register}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
          /> */}
          <HowDidyouGetInjured
            className={state.showSlide1}
            // className={state.showSlide3}
            textHeading="How did you get injured?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            prevSlide={prevSlide}
            setGtmvalue={setGtmvalue}
          />
          <TypeOfInjury
            // className={state.showSlide4}
            className={state.showSlide2}
            textHeading="Type of Injury?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            prevSlide={prevSlide}
          />

          <WereYouInjured
            // className={state.showSlide1}
            className={state.showSlide3}
            textHeading="Were you injured in the last 3 years?"
            validation={register}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
          />

          <WorkingWithAttorney
            className={state.showSlide4}
            // className={state.showSlide2}
            textHeading="Are you already working with a solicitor?"
            validation={register}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
          />

          <MedicalAttention
            className={state.showSlide5}
            textHeading="Did you receive medical attention?"
            validation={register}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
            setChatBoatPage={setChatBoatPage}
          />

          <ChatBot
            className={state.showSlide6}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            textHeading1="Great News! It appears you may have a successful compensation claim."
            textHeading2="Please Confirm Your Legal Name"
            textHeading3="This should appear as it would on your compensation cheque"
            textHeading="What's the best way to contact you about your claim?"
            formSubmit={formSubmit}
            state={state}
          />

          {/* <BasicDetails
            className={state.showSlide6}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
            setError={setError}
            clearErrors={clearErrors}
            textHeading1="Great News! It appears you may have a successful compensation claim."
            textHeading2="Please Confirm Your Legal Name"
            textHeading3="This should appear as it would on your compensation cheque"
          ></BasicDetails>

          <ContactDetails
            className={state.showSlide7}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            slideChange={slideChange}
            prevSlide={prevSlide}
            getValues={getValues}
            setError={setError}
            textHeading="What's the best way to contact you about your claim?"
            formSubmit={formSubmit}
          ></ContactDetails> */}

          {/* <div className="text-center safe-text">
              <img
                src="assets/CCG_PI_V1/img/ico-safe.png"
                className="safe-text"
                alt=""
              />{" "}
              Safe | Secure | Privacy Protected
            </div>
            <div className="form-check offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 text-center">
              <label
                className="form-check-label text-black fs-6 mt-0"
                htmlFor="accept"
              >
                I consent for compensation genie to process the above data and
                (personal injury) health data to contact me in relation to a
                possible claim. To read more about how we process your personal
                data, please see our{" "}
                <a
                  href="https://form.compensationgenie.com/privacy.html"
                  target="_blank"
                >
                  {" "}
                  Privacy Policy.
                </a>
              </label>
            </div> */}
        </form>
      </div>
    </>
  );
};

export default FormAD_PI_V6_1;
