import React from "react";
import "../../../../assets/AD_PI_CC_V5/css/privacy.scss";
import Footer from "./Footer";
import Header from "./Header";

const PrivacyDocument = () => {
  return (
    <>
      <div>
        <Header />
        <section className="privacy">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <h2>
                  <b>Privacy Policy</b>
                </h2>
                <h4>Purpose</h4>
                <p className="mb-0">
                  We have created this Privacy Policy to set out your rights
                  about the personal data we hold about you and how Accell
                  Digital and any 3rd parties we engage with collect, handle and
                  protect your personal data, and the choices you have about it.
                  Accell Digital is committed to ensuring that your privacy is
                  protected. Should we ask for certain information by which you
                  can be identified, then you can be assured that it will only
                  be used in accordance with this document.
                </p>
                <h4>Definitions and Interpretation</h4>
                <p>
                  In this Policy the following terms shall have the following
                  meanings:{" "}
                </p>
                <p>
                  “Data” means collectively all information that you submit to
                  Accell Digital and it’s associated trading names. This
                  definition shall, where applicable, incorporate the
                  definitions provided in the Data Protection Act 1998;
                </p>
                <p>
                  “Accell Digital” means Accell Digital Ltd of registered
                  address 7 BELL YARD, LONDON WC2A 2JR
                </p>
                <p>
                  “UK and EU Cookie Law” means the Privacy and Electronic
                  Communications (EC Directive) Regulations 2003 as amended by
                  the Privacy and Electronic Communications (EC Directive)
                  (Amendment) Regulations 2011;
                </p>
                <p>
                  “User” means any third party that accesses the Website and is
                  not employed by Accell Digital Ltd and acting in the course of
                  their employment; and
                </p>
                <p>
                  “Website” means the website that you are currently using (
                  <a href="#">www.accelldigital.co.uk</a>) unless expressly
                  excluded by their own terms and conditions.
                </p>
                <h4>Who are we</h4>
                <p>Accell Digital,</p>
                <p>7 Bell Yard,</p>
                <p>London,</p>
                <p>WC2A 2JR.</p>
                <p>Registered in England and Wales.</p>
                <p>ICO Number: ZB239690.</p>
                <p>VAT Number – 389529826.</p>
                <p>FCA Firm Reference Number FRN964366</p>
                <h4>Data Protection Officer</h4>
                <p>
                  If you have any questions about this policy, please contact
                  us.
                </p>
                <h4>Data collection</h4>
                <p>The information collected is (but not limited to):</p>
                <ul className="mbb">
                  <li>
                    <p>name</p>
                  </li>
                  <li>
                    <p>date of birth</p>
                  </li>
                  <li>
                    <p>job title</p>
                  </li>
                  <li>
                    <p>profession</p>
                  </li>
                  <li>
                    <p>
                      contact information such as email addresses, telephone
                      numbers and physical address
                    </p>
                  </li>
                  <li>
                    <p>
                      demographic information such as postcode, preferences and
                      interests
                    </p>
                  </li>
                  <li>
                    <p>IP address (automatically collected)</p>
                  </li>
                  <li>
                    <p>
                      And any information that relates specifically to the
                      service provided on the website
                    </p>
                  </li>
                </ul>
                <h4>Data Transfers</h4>
                <p>
                  Accell Digital may transfer personal data to countries outside
                  of the UK and/or EEA. If data is transferred outside of the
                  EEA, Accell Digital will put in place Standard Contractual
                  Clauses with the Data Controller or Data Processor which
                  contractually obliges them to protect your information to the
                  same standard required by the General Data Protection
                  Regulation and Data Protection Act 2018 post 31 December 2020.
                </p>
                <h4>Log data</h4>
                <p>
                  When you use the Accell Digital website, we record your
                  Internet Protocol (IP) which is automatically sent by your
                  browser.
                </p>
                <h4>How your personal data is used</h4>
                <p>
                  In order to provide our services to you it is necessary to
                  collect the information we do. This allows us to provide an
                  interesting, relevant and personal experience. The information
                  may be used to:-
                </p>
                <ul className="mbb">
                  <li>
                    <p>Maintain internal record keeping</p>
                  </li>
                  <li>
                    <p>Improve the products and services offered to you</p>
                  </li>
                  <li>
                    <p>
                      Keep you up to date on Accell Digital activities, products
                      and services
                    </p>
                  </li>
                  <li>
                    <p>Contact you for the provision of the service/s</p>
                  </li>
                  <li>
                    <p>Answer questions and comments</p>
                  </li>
                </ul>
                <p>In addition to the above, with your consent we will:-</p>
                <ul className="mbb">
                  <li>
                    <p>
                      Send you promotional information about third parties which
                      we think you may find interesting
                    </p>
                  </li>
                </ul>
                <h4>How your personal information is shared</h4>
                <p>
                  Some of the information you provide is shared with 3rd parties
                  to comply with law or enable us to provide a service to you.
                </p>
                <ul className="mbb">
                  <li>
                    <p>
                      Payment companies used to make payment to Accell Digital.
                      The information shared is what is necessary for you to
                      make the payment.
                    </p>
                  </li>
                  <li>
                    <p>
                      If requested by law enforcement or government agencies to
                      comply with the law.
                    </p>
                  </li>
                  <li>
                    <p>Third party websites and services</p>
                  </li>
                </ul>
                <p>
                  Accell Digital may, from time to time, employ the services of
                  other parties for dealing with matters that may include, but
                  are not limited to, payment processing, delivery of purchased
                  items, search engine facilities, advertising and marketing. 
                  The providers of such services have access to certain personal
                  Data provided by Users of this Website. Any data used by such
                  parties is used only to the extent required by them to perform
                  the services that Accell Digital requests.  Any use for other
                  purposes is strictly prohibited.  Furthermore, any Data that
                  is processed by third parties shall be processed within the
                  terms of this Policy.
                </p>
                <h4>Security and storage</h4>
                <p>
                  We are committed to ensuring your data is secure. In order to
                  prevent unauthorised access or disclosure, we have suitable
                  physical, electronic and managerial procedures to safeguard
                  and secure the information we collect.
                </p>
                <p>
                  Information and records relating to service users will be
                  stored securely and will only be accessible to authorised
                  personnel. We will hold your details for as long as you use
                  our services, or as long regulations stipulate, whichever is
                  the longer.
                </p>
                <p>
                  We may store the information we hold about you outside of your
                  home country. The privacy policies and laws to access your
                  information by law enforcement in these countries may be
                  different to your home country. If data is transferred outside
                  the European Economic Area (EEA) it is only to countries that
                  have been deemed to provide an adequate level of protection
                  for personal data by the European Commission.
                </p>
                <h4>Your rights</h4>
                <p>
                  You have options which you can apply in relation to the
                  information we have about you, to do this please contact us.
                  The options are explained below:-
                </p>
                <ol className="mbb">
                  <li>
                    <p>
                      If you wish to access the information we hold about you,
                      we’ll usually provide it within 30 days of receiving your
                      request.
                    </p>
                  </li>
                  <li>
                    <p>
                      You can ask us to stop using your information,
                      please contact us to do this. If you wish to stop any
                      emails/ texts we send, you may unsubscribe at any time by
                      emailing us:
                      <a href="#">hello@accelldigital.co.uk.</a>
                    </p>
                  </li>
                  <li>
                    <p>
                      You can ask us to send the information we hold about you
                      to another organisation, where it is technically possible
                      to do.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you think we haven’t complied with the data protection
                      laws, please let us at Accell Digital  know in the first
                      instance, to give us time to investigate or you can log a
                      complaint to the ICO
                    </p>
                  </li>
                  <li>
                    <p>
                      If you wish to update or delete the information we hold
                      about you please contact us.
                    </p>
                  </li>
                </ol>
                <h4>Consent</h4>
                <ul className="mbb">
                  <li>
                    <p>
                      Upon your consent, Accell Digital may contact you via the
                      following methods, by email and telephone promoting our
                      products and services to you.
                    </p>
                  </li>
                  <li>
                    <p>
                      If you do not wish to receive emails or calls promoting
                      our products and services, please  unsubscribe If you wish
                      to know more about how your data is handled,
                      please contact us.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default PrivacyDocument;
