import React,{useState} from 'react';
import CheckBox from '../../../UI/CheckBox';
import QuestionnaireCheckboxModal from '../Followup/QuestionnaireCheckboxModal';
import {questinnare7Sub1,questinnare7Sub2} from '../../../../Constants/Questions';

const QuestionnaireFlpCheckboxWrapper = ({onCheckBoxChange,nextOnClick,dispError,onModalClick,display}) => {
    const [showQest7Sub1,setShowQest7Sub1] = useState(false);
    const [showQest7Sub2,setShowQest7Sub2] = useState(false);
    const onAnscheckSub1 = (e) => {
        setShowQest7Sub1(false);
        setShowQest7Sub2(false);
        if(e.target.checked == true){
            if(e.target.value=== "27" ){
                setShowQest7Sub1(true);
            }else if(e.target.value=== "32"){
                setShowQest7Sub2(true);  
            }
        }
    }
    

    return (
        <>
            <div className="radio-box question animated fadeInUp">
                <h4 className="flp-qtext">
                Please specify your life events so we can continue making your claim as strong as possible.
                </h4>
                <div className="form-group">
                    <div className="cstfrm" id="question_group_66" > 
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Job Loss" value="26" dataId="26"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Debt Issues" value="27" dataId="27" 
                            onChange={onCheckBoxChange}
                            onClick={onAnscheckSub1}
                        /> 
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Debt Management" value="28" dataId="28"
                            onChange={onCheckBoxChange}
                        /> 
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Receiving Benefits" value="29" dataId="29"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Mental Health Issues" value="30" dataId="30"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Serious Illness" value="31" dataId="31"
                            onChange={onCheckBoxChange}
                        /> 
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Language Barrier" value="32" dataId="32" 
                            onChange={onCheckBoxChange}
                            onClick={onAnscheckSub1}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Just Arrived in the UK" value="33" dataId="33"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Caring Responsibilities" value="34" dataId="34"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Death in Family" value="35" dataId="35"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Divorce" value="36" dataId="36"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Not a UK Resident" value="37" dataId="37"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="PTSD" value="38" dataId="38"
                            onChange={onCheckBoxChange}
                        />
                        <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="Military Overseas" value="39" dataId="39"
                            onChange={onCheckBoxChange}
                        />
                        {/* <CheckBox 
                            className="custom-checkbox" labelClassName="br radioa"
                            CheckBoxText="N/A" value="50" dataId="50"
                            onChange={onCheckBoxChange}
                        /> */}
                        <button type="button" className="btn-primary form-control" onClick={nextOnClick} >Next > </button>
                        <span className={`error_msg ${dispError}`}>Please select atleast one option</span>
                    </div>
                </div>
            </div>
            <QuestionnaireCheckboxModal questArr={questinnare7Sub1} popDisplay={showQest7Sub1} onModalClick={onModalClick} />
            <QuestionnaireCheckboxModal questArr={questinnare7Sub2} popDisplay={showQest7Sub2} onModalClick={onModalClick}/>
        </>
    )
}

export default QuestionnaireFlpCheckboxWrapper;