import React from "react";

const Loader = ({ showLoad }) => {
  return (
    <>
      <div id="myDiv" className={`space text-center ${showLoad}`}>
        <img src="/assets/Followup/img/review.gif" alt="" style={{ width: '100px', marginTop: '20px' }} />
        <h3 className="flp-loader">Analysing your application....</h3>
      </div>
    </>
  );
};

export default Loader;
