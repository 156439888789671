import React, { useState, useContext } from "react";
import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { useEmailWebhook } from "../../../../Hooks/useEmailWebhook";
import { currentdate, currenttime } from "../../../../Constants/Constants";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import ConsentSection from "./ConsentSection";
import PrivacyPopUp from "./PrivacyPopUp";
import privacyImg from "../../../../assets/AD_PI_V4_B/img/privacy.png";

const ContactDetails = ({
  className,
  validation,
  validationMsg,
  trigger,
  slideChange,
  prevSlide,
  textHeading,
  getValues,
  setError,
  formSubmit,
}) => {
  const { emailValidation, emailValidationErr } = useEmailValidation();
  const { weHookApiCall } = useEmailWebhook();
  const { phoneValidation } = usePhoneValidation();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [disabled, setDisabled] = useState(false);
  const [loader, setloader] = useState(false);
  const [privacyShow, setPrivacyShow] = useState(false);
  const [webHookState, setWebHookState] = useState(false);

  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values["txtPhone"];
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone);
      console.log(getPhoneValidation.data);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["txtEmail"];
    if (email !== "") {
      const getEmailValidation = await emailValidation(
        email,
        visitorParameters.visitor_parameters.uuid
      );
      if (emailValidationErr) {
        return 1;
      }
      if (
        typeof getEmailValidation === "undefined" ||
        getEmailValidation === null
      ) {
        return 2;
      }

      if (getEmailValidation.data.status_code === 0) {
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email Address",
        });
        return 0;
      } else {
        return 1;
      }
    }
  };

  const validNumber = (evt) => {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      evt.preventDefault();
      return false;
    } else {
      return true;
    }
  };

  const submitForm = async (e) => {
    setDisabled(true);
    setloader("loader_img");
    const formvalues = getValues();
    const emailValidChk = await trigger("txtEmail");
    const phoneValidChk = await trigger("txtPhone");

    if (emailValidChk && phoneValidChk) {
      if ((await emailValidCheck()) === 2 || emailValidationErr) {
        if (!webHookState) {
          const message = `
      {
        Service Name : Email Single Endpoint API
        Project ID : ADPI060
        UUID : ${visitorParameters.visitor_parameters.uuid}
        Error Reason : Validation API Timeout
        Datetime : ${currentdate} ${currenttime}
      }
      `;
          const getWebhookRes = await weHookApiCall(message);
          console.log("api delay");
        }
        setWebHookState(true);

        let mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!formvalues["txtEmail"].match(mailformat)) {
          setError("txtEmail", {
            type: "manual",
            message: "Please enter valid Email Address",
            shouldFocus: true,
          });
          return false;
        }

        setDisabled(true);
      }

      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        console.log("message:");
        setDisabled(true);
        formSubmit();
      } else {
        setDisabled(false);
        setloader(false);
      }
    } else {
      setDisabled(false);
      setloader(false);
    }
  };

  return (
    <>
      <div id="slide9" className={className}>
        <div className="form_cont col-lg-12 col-md-12 col-sm-12 text-center  mt-1 dmy px-3 py-0 py-sm-3">
          <p className="mb-0  fw-bold qstn_1">{textHeading}</p>
          <div className="input-group mb-3">
            <label className="label_contact w-100  fw-bold text-start">
              Phone Number
            </label>
            <Telephone
              name="txtPhone"
              className="form-control"
              placeholder="Phone Number"
              maxlength="11"
              onBlur={phoneValidCheck}
              onKeyPress={validNumber}
              validation={validation({
                pattern: {
                  value: /^[0-9]*$/i,
                  message: "Please Enter Valid Phone Number",
                },
                required: "Please Enter Valid Phone Number",
              })}
              // validationMsg={
              //   validationMsg.txtPhone && validationMsg.txtPhone.message
              // }
            ></Telephone>

            <div className="input-group-append">
              <span className={"input-group-text"}>
                <img src={privacyImg} alt="" className="privcy pull-right" />
              </span>
            </div>
            {validationMsg.txtPhone && (
              <span className="error_msg " id="phone_err">
                {validationMsg.txtPhone.message}
              </span>
            )}
          </div>
          <div className="input-group mb-3">
            <label className="label_contact w-100  fw-bold text-start">
              Email Address
            </label>
            <Email
              name="txtEmail"
              className="form-control"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={validation({
                required: "Please Enter Valid Email Address",
                // pattern: {
                //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                //   message: "Invalid Email Address",
                // },
              })}
              // validationMsg={
              //   validationMsg.txtEmail && validationMsg.txtEmail.message
              // }
            ></Email>
            <i className="tick fa_privcy" style={{ display: "none" }}></i>
            <div className={"input-group-append"}>
              <span className={"input-group-text"}>
                <img src={privacyImg} alt="" className={"privcy pull-right"} />
              </span>
            </div>
            {validationMsg.txtEmail && (
              <span className="error_msg " id="email_err">
                {validationMsg.txtEmail.message}
              </span>
            )}
          </div>
          {/* <InputButton
            name="formSubmitBtn"
            className={`next-but btn my-bt sctop1 text-white fw-bold rounded ${loader}`}
            value="Get My Free Quote >>"
            btnType="button"
            onClick={submitForm}
            disabled={disabled}
          /> */}
          <InputButton
            name="formSubmitBtn"
            className={`next-but btn my-bt mb-3 text-white fw-bold rounded false ${loader}`}
            value="Start My Claim"
            btnType="button"
            onClick={submitForm}
            disabled={disabled}
          />
          <a
            name="medical_attention"
            className="back_btn sctop1"
            onClick={prevSlide}
          >
            <img src="assets/AD_PI_V3/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        {/* <ConsentSection /> */}
        <div className="text-center safe-text">
          <img
            src="assets/AD_PI_V3/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
        <div className="form-check p-2 offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 text-center">
          <label
            className="form-check-label text-consent text-black fs-6 mt-0"
            htmlFor="accept"
          >
            I consent for Accell Digital Limited to process the above data and
            (personal injury) health data to contact me in relation to a
            possible claim. To read more about how we process your personal
            data, please see our{" "}
            <a onClick={() => setPrivacyShow(true)}>
              <u>Privacy Policy</u>
            </a>
            .
          </label>
        </div>
        <PrivacyPopUp
          privacyShow={privacyShow}
          setPrivacyShow={setPrivacyShow}
        />
      </div>
    </>
  );
};
export default ContactDetails;
