import React from "react";
import Footer from "./Footer";
import "../../../../assets/AD_PI_CC_V5/css/cookie.scss";
import Header from "./Header";

const CookieDocument = () => {
  return (
    <div>
      <Header />
      <section className="privacy">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12">
              <h2>
                <b>Cookie Policy</b>
              </h2>
              <h3>What Are Cookies</h3>
              <p>
                As is common practice with almost all professional websites this
                site uses cookies, which are tiny files that are downloaded to
                your computer, to improve your experience. This page describes
                what information they gather, how we use it and why we sometimes
                need to store these cookies. We will also share how you can
                prevent these cookies from being stored however this may
                downgrade or ‘break’ certain elements of the sites
                functionality.
              </p>
              <p>
                For more general information on cookies see aboutcookies.org.uk
              </p>
              <h3>How We Use Cookies</h3>
              <p>
                We use cookies for a variety of reasons detailed below.
                Unfortunately in most cases there are no industry standard
                options for disabling cookies without completely disabling the
                functionality and features they add to this site. It is
                recommended that you leave on all cookies if you are not sure
                whether you need them or not in case they are used to provide a
                service that you use.
              </p>
              <h3>Disabling Cookies</h3>
              <p>
                You can prevent the setting of cookies by adjusting the settings
                on your browser (see your browser Help for how to do this). Be
                aware that disabling cookies will affect the functionality of
                this and many other websites that you visit. Disabling cookies
                will usually result in also disabling certain functionality and
                features of this site. Therefore it is recommended that you do
                not disable cookies.
              </p>
              <h3>The Cookies We Set</h3>
              <ul className="mbb">
                <li>
                  <p>
                    Forms related cookies: when you submit data through a form
                    such as our 'contact us' form cookies may be set to remember
                    your user details for future correspondence.
                  </p>
                </li>
              </ul>
              <h3>Third Party Cookies</h3>
              <p>
                In some special cases we also use cookies provided by trusted
                third parties. The following section details which third party
                cookies you might encounter through this site.
              </p>
              <ul>
                <li>
                  <p>
                    This site uses Google Analytics which is one of the most
                    widespread and trusted analytics solution on the web for
                    helping us to understand how you use the site and ways that
                    we can improve your experience. These cookies may track
                    things such as how long you spend on the site and the pages
                    that you visit so we can continue to produce engaging
                    content. For more information on Google Analytics cookies,
                    see the official Google Analytics page.
                  </p>
                </li>
                <li>
                  <p>
                    From time to time we test new features and make subtle
                    changes to the way that the site is delivered. When we are
                    still testing new features these cookies may be used to
                    ensure that you receive a consistent experience whilst on
                    the site whilst ensuring we understand which optimisations
                    our users appreciate the most.
                  </p>
                </li>
              </ul>
              <h3>More Information</h3>
              <p>
                If you are still looking for more information then you can
                contact us:
                <a href="#">hello@accelldigital.co.uk</a>
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CookieDocument;
