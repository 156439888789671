import { useCallback, useState } from "react";
import { Api } from "../api/Api";

const useJourneyStats = () => {
  const [loading, isLoading] = useState(false);

  const saveJourneyStats = useCallback(
    async (uuid, slide_type, slide_question, slide_value, type, split_name) => {
      isLoading(true);

      try {
        await Api.post("api/v1/save-journey-status", {
          uuid,
          slide_type,
          slide_question,
          slide_value,
          type,
          split_name,
        });
      } catch (error) {}

      isLoading(false);
    },
    []
  );

  return { saveJourneyStats, loading };
};

export default useJourneyStats;
