import React from "react";

const TextField = ({
  name,
  id,
  type,
  placeholder,
  className,
  onChange,
  onBlur,
  validation,
  validationMsg,
  onkeypress,
  value,
  maxlength,
  style,
  keypress,
  privacyIcon = false,
}) => {
  return (
    <>
      <div className={`${privacyIcon && "input-group"}`}>
        <input
          name={name}
          id={id}
          placeholder={placeholder}
          className={className}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onkeypress}
          ref={validation}
          defaultValue={value}
          maxLength={maxlength}
          style={style}
          onKeyPress={keypress}
        />
        {privacyIcon && (
          <div className={"input-group-append"}>
            <span
              className={"input-group-text"}
              style={{ paddingTop: "0.89rem", paddingBottom: "0.89rem" }}
            >
              <img
                src="/assets/AD_PI_V4/img/privacy.png"
                alt=""
                className={"privcy pull-right"}
              />
            </span>
          </div>
        )}
      </div>
      {validationMsg && (
        <>
          <span className="error_msg">{validationMsg}</span>
        </>
      )}
    </>
  );
};
export default TextField;
