import React from "react";
import Header from "../../Includes/Layouts/AD_PI_V3/Header";
import Footer from "../../Includes/Layouts/AD_PI_V3/Footer";
import ContentSection from "../../Includes/Layouts/AD_PI_V3/ContentSection";
import AgreementSection from "../../Includes/Layouts/AD_PI_V3/AgreementSection";
import "../../../assets/AD_PI_V3/css/style.scss";
import "../../../assets/AD_PI_V3/css/animate.css";
import FormAD_PI_V3 from "../../Forms/FormAD_PI_V3";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import StaticSection from "../../Includes/Layouts/AD_PI_V3/StaticSection";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

const AD_PI_V3 = () => {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "AD_PI_V3",
    "AD_PI_V3"
  );
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="AD_PI_V3" />
      <div className="AD_PI_V3" id="AD_PI_V3">
        <div className="graysection">
          <div className="PI-1">
            <Header />
            <section className="SECT">
              <div className="container">
                <div className="row justify-content-center">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 pad0"
                    id="formpartsec"
                  >
                    <h1 className="d-block d-sm-none d-md-none d-lg-none text-center text-white fw-bold">
                      Let our expert team help you decide
                    </h1>
                    {/* <h2 className="d-block d-sm-none d-md-none d-lg-none text-center text-white">
                      By answering a few simple questions we will be able to
                      tell you if you may be owed thousands in compensation for
                      a personal injury including: accidents at work, road
                      traffic accidents, slips trips and falls + more.
                    </h2> */}
                    <h2 className="d-block d-sm-none d-md-none d-lg-none text-center text-white">
                      By answering a few simple questions, we will be able to
                      see if you could be entitled to thousands in compensation
                      for a personal injury including: accidents at work, road
                      traffic accidents, slips trip and falls + more.
                    </h2>
                    <div className="form_part bg-white px-2 py-1">
                      <h1 className="d-none d-sm-block d-md-block d-lg-block text-center fw-bold pt-3">
                        Let the Claims Expert Help You Maximise Compensation
                      </h1>
                      {/* <h2 className="d-none d-sm-block d-md-block d-lg-block text-center py-2 px-4">
                        By answering a few simple questions we will be able to
                        tell you if you may be owed thousands in compensation
                        for a personal injury including: accidents at work, road
                        traffic accidents, slips trips and falls + more.
                      </h2> */}
                      <h2 className="d-none d-sm-block d-md-block d-lg-block text-center py-2 px-4">
                        We offer a FREE no obligation, no win-no fee*
                        consultation. Get started below by answering a few
                        questions. It's quick and easy.
                      </h2>
                      <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center d-lg-none d-sm-none d-md-none d-block">
                        <span className="start-but bg-white pt-4 mb-1 fs-5 d-inline-block position-relative border border-5 rounded-circle text-secondary">
                          Start
                          <br />
                          <img src="assets/AD_PI_V3/img/arrow-b.png" alt="" />
                        </span>
                      </div>
                      <div className="pointsec">
                        <ul>
                          <li> Accident in a Private/Public Place</li>
                          {/* <li> Road Traffic Accident</li> */}
                          <li> Work Accident</li>
                          <li> Personal Injury</li>
                        </ul>
                      </div>
                      <div className="clearfix"></div>
                      <FormAD_PI_V3
                        splitName={DynamicRouteSplitName}
                        nextPage={DynamicRouteNextPage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </section>
            <ContentSection />
            {/* <StaticSection/> */}
            <AgreementSection />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default AD_PI_V3;
