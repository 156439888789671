import React, { lazy, useEffect, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import * as EnvConstants from "../src/Constants/EnvConstants";
import { QueryStringProvider } from "./Contexts/QueryString";
import { VisitorParamsProvider } from "./Contexts/VisitorParams";
import { queryString } from "./Utility/QueryString";
import HomePage from "./Components/Pages/HomePage";
import FbPixel from "./Components/Pages/FbPixel";
import "./App.scss";
import Split_1 from "./Components/Pages/Splits/Split_1";
import AD_PI_V3 from "./Components/Pages/Splits/AD_PI_V3";
import { RedirectProvider } from "./Contexts/RedirectContext";
import Questionnaire from "./Components/Pages/Questionnaire";
import Followup from "./Components/Pages/Splits/Followup";
import Preview from "./Components/Pages/Preview";
import Proceed from "./Components/Pages/Proceed";
import Unqualified from "./Components/Pages/Unqualified";
import Signature from "./Components/Pages/Signature";
import Thankyou from "./Components/Pages/Thankyou";
import IdUpload from "./Components/Pages/IdUpload";
import PreviewLoa from "./Components/Pages/PreviewLoa";
import NotFound from "./Components/Pages/NotFound";
import ADV_1 from "./Components/Pages/Advertorials/ADV_1";
import ADV1_Contact from "./Components/Pages/Advertorials/ADV1_Contact";
import AD_PI_V4 from "./Components/Pages/Splits/AD_PI_V4";
import AD_PI_V4_B from "./Components/Pages/Splits/AD_PI_V4_B";
import AD_PI_CC_V5 from "./Components/Pages/Splits/AD_PI_CC_V5";
import Agent from "./Components/Pages/Agent";
import PrivacyDocument from "./Components/Includes/Layouts/AD_PI_CC_V5/PrivacyDocument";
import CookieDocument from "./Components/Includes/Layouts/AD_PI_CC_V5/CookieDocument";
import AD_PI_V6 from "./Components/Pages/Splits/AD_PI_V6";
import AD_PI_V7 from "./Components/Pages/Splits/AD_PI_V7";
import AD_PI_V6_1 from "./Components/Pages/Splits/AD_PI_V6_1";
import ComplaintsProcedure from "./Components/Pages/ComplaintsProcedure";

const App = () => {
  const [visitorParameters] = useState({ visitor_parameters: [], data: [] });
  const [redirectUrl] = useState({ redirectUrl: "" });
  useEffect(() => {
    document.title = EnvConstants.AppTitle;
  }, []);
  return (
    <>
      <VisitorParamsProvider value={{ visitorParameters }}>
        <QueryStringProvider value={{ queryString }}>
          <RedirectProvider value={{ redirectUrl }}>
            <BrowserRouter>
              <Route exact path="/" component={HomePage} />
              <Route exact path="/HUB" component={HomePage} />
              <Route exact path={["/Split_1"]} component={Split_1} />
              <Route exact path={["/AD_PI_V3"]} component={AD_PI_V3} />
              <Route exact path={["/AD_PI_CC_V5"]} component={AD_PI_CC_V5} />
              <Route exact path={["/agent"]} component={Agent} />
              <Route
                exact
                path={["/privacy-policy"]}
                component={PrivacyDocument}
              />
              <Route
                exact
                path={["/cookie-policy"]}
                component={CookieDocument}
              />
              <Route exact path={"/questionnaire"} component={Questionnaire} />
              <Route exact path={"/followup"} component={Followup} />
              <Route exact path={["/splash-page"]} component={Preview} />
              <Route exact path={"/proceed"} component={Proceed} />
              <Route exact path={"/unqualified"} component={Unqualified} />
              <Route exact path={"/signature"} component={Signature} />
              <Route exact path={["/fbpixel"]} component={FbPixel} />
              <Route exact path={"/thankyou"} component={Thankyou} />
              <Route exact path={["/id-upload"]} component={IdUpload} />
              <Route exact path={["/preview"]} component={PreviewLoa} />
              <Route exact path={["/404"]} component={NotFound} />
              <Route exact path={["/ADV_1"]} component={ADV_1} />
              <Route exact path={["/ADV1/contact"]} component={ADV1_Contact} />
              <Route exact path={["/AD_PI_V4"]} component={AD_PI_V4} />
              <Route exact path={["/AD_PI_V4B"]} component={AD_PI_V4_B} />
              <Route exact path={["/AD_PI_V6"]} component={AD_PI_V6} />
              <Route exact path={["/AD_PI_V6.1"]} component={AD_PI_V6_1} />
              <Route exact path={["/AD_PI_V7"]} component={AD_PI_V7} />
              <Route
                exact
                path={["/complaints-procedure"]}
                component={ComplaintsProcedure}
              />
            </BrowserRouter>
          </RedirectProvider>
        </QueryStringProvider>
      </VisitorParamsProvider>
    </>
  );
};

export default App;
