import React, { useState, useEffect, useRef, useReducer } from 'react';
import Modal from 'react-bootstrap/Modal';
import QuestionnaireFlpWrapper from '../../Layouts/Followup/QuestionnaireFlpWrapper';
import QuestionnaireFlpCheckboxWrapper from "../../../Includes/Layouts/Followup/QuestionnaireFlpCheckboxWrapper";
import QuestionnaireFlpWrapperSub from '../../Layouts/Followup/QuestionnaireFlpWrapperSub';
import { useHistory } from "react-router-dom";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";

import {
  questinnare1, questinnare2, questinnare4,
  questinnare5, questinnare3, questinnare8, questinnare6, questinnare11, questinnare12
} from '../../../../Constants/Questions';
const initialState = {
  showSlide1: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide"
 
}
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case 'showSlideChange': {
      if (action.payload.selectedQuest == 5) {
        
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide"
         
        }
      } else if (action.payload.selectedQuest == 6) {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide"        
        }
      }
      else if (action.payload.selectedQuest == 7) {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide"
        
        }
      }
      else if (action.payload.selectedQuest == 8) {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide"
        
        }
      }
      else if (action.payload.selectedQuest == 9) {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide"
          
        }
      }
      else if (action.payload.selectedQuest == 10) {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "show",
          showSlide7: "hide",
          showSlide8: "hide"
          
        }
      }
      else if (action.payload.selectedQuest == 11) {

        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "show",
          showSlide8: "hide"
          
        }


      }
      else if (action.payload.selectedQuest == 12) {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "show"
          
        }
      }
  
   
  
      else {
        return {
          ...state
        }
      }
    }
  }
}
const QuestionsModal = ({
  questionsPop,
  allFollowupData,
  setPendingStatus,
  pendingQuestionnaire,
  setLoader,
  hideLoader,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [slide, setSlide] = useState('');
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [checkboxValues, SetCheckboxValues] = useState([]);
  const [modalSub1Ans, setModalSub1Ans] = useState(null);
  const [modalSub2Ans, setModalSub2Ans] = useState(null);
  const [displayError, SetDisplayError] = useState('d-none');
  const [displayInputWrapp, setDisplayInputWrapp] = useState('none');
  const [displayError15, SetDisplayError15] = useState('d-none');
  const [quest15Val, setQuest15Val] = useState('');
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const [quest9, setQuest9] = useState('');
  const [quest6, setQuest6] = useState('');

  useEffect(() => {
    setShow(questionsPop);
  }, [questionsPop])
  useEffect(() => {
    if (pendingQuestionnaire.length != 0) {
      const totalQuestArr = pendingQuestionnaire.pendingQuestions;
 
      
      if (quest6 == 'no') {
        setCompletedQuestArr([...completedQuestArr]);
        var questIntersection = totalQuestArr.filter(x => !completedQuestArr.includes(x));
      } else {
        var questIntersection = totalQuestArr.filter(x => !completedQuestArr.includes(x));
      }
    
      const selectedQuest = questIntersection[0];
      if(selectedQuest)
      {
        dispatch({ type: 'showSlideChange', payload: { selectedQuest } });
      }
      if (selectedQuest == undefined && quest6 != 'yes') {
        setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' });
        setShow(false);
        setLoader();
        setTimeout(() => { hideLoader() }, 1000);
      } else {
        if (quest9 == 'yes') {
            setDisplayInputWrapp('block');
            setQuest9('');
        }
        else if (quest9 == 'no') {
            setDisplayInputWrapp('none');
            setQuest9('');
            dispatch({ type: 'showSlideChange', payload: { selectedQuest } });
        }
        if (quest6 == 'yes') {
          dispatch({ type: 'showSlideChange', payload: {  } });
          setQuest6('');
        }
        else if (quest6 == 'no') {
          dispatch({ type: 'showSlideChange', payload: { quest6 } });
          setQuest6('');
        }
        else {
          dispatch({ type: 'showSlideChange', payload: {  } });
        }
      }
    }
  }, [pendingQuestionnaire, completedQuestArr])
  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute('data-ans-id'));
    let selectedQuest = parseInt(e.target.getAttribute('data-quest-id'));
    let question_data = {
      "question_id": selectedQuest,
      "option_id": selectedAns,
      "input_answer": ""
    }
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData.flpData.followup_data,
      'followup_question_store'
    )
    // if(selectedAns == 18){
    //     setCompletedQuestArr([...completedQuestArr,7,6]);
    // }else{
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    //}
  }
  const nextOnClick = () => {
    if (checkboxValues.length > 0) {
      const selectedQuest = 11;
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);
      const checkBoxArr = checkboxValues.map(async (answer, index) => {
        let question_data = '';
        if (answer === '27') {
          question_data = {
            "question_id": "11",
            "option_id": answer,
            "input_answer": modalSub1Ans
          }
        } else if (answer === '32') {
          question_data = {
            "question_id": "11",
            "option_id": answer,
            "input_answer": modalSub2Ans
          }
        } else {
          question_data = {
            "question_id": "11",
            "option_id": answer,
            "input_answer": ""
          }
        };
        const response = await saveFollowupUserQuestions(
          question_data,
          allFollowupData.flpData.followup_data,
          'followup_question_store'
        )
      })
    }
    else {
      SetDisplayError('d-block');
    }
  }
  const handleCheckBoxChange = (e) => {
    let checkVal = e.target.value;
    if (e.target.checked == true) {
      SetCheckboxValues([...checkboxValues, checkVal]);
    } else {
      SetCheckboxValues(checkboxValues.filter((e) => (e !== checkVal)))
    }
  }

  const onModalClick = (e) => {
    let selectedAns = e.target.getAttribute('data-ans-id');
    let selectedQuest = e.target.getAttribute('data-quest-id');
    if (selectedQuest === 'sub1') {
      setModalSub1Ans(selectedAns);
    } else if (selectedQuest === 'sub2') {
      setModalSub2Ans(selectedAns);
    }
  }
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="followupPage modal-content-q" backdrop="static"
      keyboard={false}
    >
      <div className="modal-body sign-sec">
        <form>
          <div className={`radio-box question1 animated fadeInUp ${state.showSlide1}`} id="breakdown1" >
            <QuestionnaireFlpWrapper
              questArr={questinnare1}
              labelclicked={onselectAnswer}
            />
          </div>
          <div className={`radio-box question2 animated fadeInUp ${state.showSlide2}`} id="breakdown2" >
            <QuestionnaireFlpWrapper
              questArr={questinnare2}
              labelclicked={onselectAnswer}
            />
          </div>
          <div className={`radio-box question3 animated fadeInUp ${state.showSlide3}`} id="breakdown3" >
            <QuestionnaireFlpWrapper
              questArr={questinnare3}
              labelclicked={onselectAnswer}
            />
          </div>
          <div className={`radio-box question4 animated fadeInUp ${state.showSlide4}`} id="breakdown4" >
            <QuestionnaireFlpWrapper
              questArr={questinnare4}
              labelclicked={onselectAnswer}
            />
          </div>
          <div className={`radio-box question5 animated fadeInUp ${state.showSlide5}`} id="breakdown5" >
            <QuestionnaireFlpWrapper
              questArr={questinnare5}
              labelclicked={onselectAnswer}
            />
          </div>
          
          <div className={`radio-box question6 animated fadeInUp ${state.showSlide6}`} id="breakdown6" >
            <QuestionnaireFlpWrapper
              questArr={questinnare6}
              labelclicked={onselectAnswer}
            />
          </div>
          <div className={`radio-box question7 animated fadeInUp ${state.showSlide7}`} id="breakdown7" >
            <QuestionnaireFlpCheckboxWrapper
              onCheckBoxChange={handleCheckBoxChange}
              nextOnClick={nextOnClick}
              dispError={displayError}
              onModalClick={onModalClick}
            />
          </div>
          <div className={`radio-box question8 animated fadeInUp ${state.showSlide8}`} id="breakdown8" >
            <QuestionnaireFlpWrapper
              questArr={questinnare8}
              labelclicked={onselectAnswer}
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}
export default QuestionsModal;