import { useState } from "react";
import { Api, CommonEmailValidateApi,CommonEmailValidateApiTimeDelay } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";


export const useEmailValidation = () => {
  const [emailValidationErr, setEmailValidationErr] = useState(false);
  // const emailValidation = async (email) => {
  //   const getValidation = await Api.get(
  //     "api/v1/get-email-validation?email=" + email,
  //     {}
  //   );
  //   return getValidation;
  // };
  const emailValidation = async (email) => {
    
    // const getValidation = await CommonEmailValidateApiTimeDelay.get(
    //   "sleep?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email='+email,
    //   {}

    const getValidation = await CommonEmailValidateApi.post(
      "v1/validate?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email='+email,
      {}
    ).catch((error) => {
      setEmailValidationErr(true);
      console.log(error);
      if (error.code === 'ECONNABORTED') {
        console.log('email endpoint request timed out');
      } else if (error.getValidation) {
        console.log("email endpoint response error");
      } else if (error.request) {
        console.log("email endpoint request error");
      } else {
        console.log("anything else");
      }

    });

    return getValidation;
  };
  return { emailValidation,emailValidationErr };
};
