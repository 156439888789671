import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import ConsentSection from "./ConsentSection";

const HowDidyouGetInjured = ({ className, slideChange, textHeading, validation, prevSlide }) => {
  const [active5, setActive5] = useState("");
  const [active6, setActive6] = useState("");
  const [active7, setActive7] = useState("");
  const [active8, setActive8] = useState("");

  const nextSlide = async (e) => {
    if (e.target.value == 5) {
      setActive5("active");
    } else {
      setActive5("");
    }

    if (e.target.value == 6) {
      setActive6("active");
    } else {
      setActive6("");
    }

    if (e.target.value == 7) {
      setActive7("active");
    } else {
      setActive7("");
    }

    if (e.target.value == 8) {
      setActive8("active");
    } else {
      setActive8("");
    }
    slideChange(e);
  };
  return (
    <>
      <div id="slide7" className={className}>
        <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center firstslide">
          <div className="mb-3">
            <label className="fw-bold mb-1">{textHeading}</label>

            <RadioButton
              value="5"
              name="how_did_you_injured"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Accident At Work"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active5}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="6"
              name="how_did_you_injured"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Medical Procedure"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active6}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="7"
              name="how_did_you_injured"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Slip Trip or Fall"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active7}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="8"
              name="how_did_you_injured"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Road Traffic Accident"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active8}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <a name="were_you_injured" className="back_btn sctop1" onClick={prevSlide}>
            <img src="assets/AD_PI_V3/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text p-3">
          <img src="assets/AD_PI_V3/img/ico-safe.png" className="safe-text" alt="" /> Safe | Secure | Privacy Protected
        </div>
      </div>
    </>
  );
};
export default HowDidyouGetInjured;
