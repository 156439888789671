import React,{ useContext} from 'react';
import {split_Flow} from "../Constants/DynamicRouting";

export const DynamicRoutingSplit = (LpName,CurrentPage) => {
  if(LpName == '' || LpName == null){
    const query = new URLSearchParams(window.location.search);
    LpName = query.get("splitName");
  }
  //console.log("kkkkkkk");
  // console.log("lp name =="+LpName);
  // console.log("current  page =="+CurrentPage);
  //console.log("---------------------------");
  //console.log(split_Flow);
  // console.log(split_Flow[LpName]);
  const currentPageKey = getKeyByValue(split_Flow[LpName],CurrentPage);
  // console.log("current key="+currentPageKey);
  // console.log(Object.keys(split_Flow[LpName]).pop())
  //console.log("---------------------------");
  //console.log("-------- Find Next Key ---------");
  const nextKey = findNext(currentPageKey, split_Flow[LpName]);
  console.log("nextpage : "+split_Flow[LpName][nextKey]);
  const DynamicRouteNextPage = split_Flow[LpName][nextKey];
  //console.log("key value" + obj[Object.keys(obj)]);
  const DynamicRouteSplitName = LpName;
  return {DynamicRouteSplitName,DynamicRouteNextPage} 
} 
const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}
function findNext(key, obj) {
  var keys = Object.keys(obj);
  return keys[(keys.indexOf(key) + 1) % keys.length];
}

