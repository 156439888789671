import React, { useState, useContext } from "react";
import RadioButton from "../../../UI/RadioButton";
import Popup from "./Popup";
import { useVisitorAnswer } from "../../../../Hooks/useVisitorAnswer";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import ConsentSection from "./ConsentSection";

const WereYouInjured = ({ className, slideChange, textHeading, validation, prevSlide }) => {
  const [active1, setActive1] = useState("");
  const [active2, setActive2] = useState("");
  const [shwPopup, setshwPopup] = useState(false);
  const { saveAnswer } = useVisitorAnswer();
  const { visitorParameters } = useContext(VisitorParamsContext);

  const nextSlide = async (e) => {
    if (e.target.value == 1) {
      setActive1("active");
    } else {
      setActive1("");
    }

    if (e.target.value == 2) {
      // const saveAns = await saveAnswer(2, e.target.value, visitorParameters.visitor_parameters);
      setActive2("active");
      setshwPopup(true);
      return false;
    } else {
      setActive2("");
    }
    slideChange(e);
  };

  return (
    <>
      <Popup
        show={shwPopup}
        setshwPopup={setshwPopup}
        message="Sorry, If you are not injured in the last 3 years, we can't help you."
      />
      <div id="slide1" className={className}>
        <div className="offset-lg-3 col-lg-6 col-md-12 form_cont text-center firstslide">
          <div className="mb-3">
            <label className="fw-bold mb-1">{textHeading}</label>
            <RadioButton
              value="1"
              name="were_you_injured"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="Yes"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active1}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="2"
              name="were_you_injured"
              className="radio-button custom-radio-button sctop1 next-but"
              labelName="No"
              labelClassName={`icon_option smoker3 m_family load sctop1 next01 text-white fw-bold nopad ${active2}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>
        <div className="text-center safe-text p-3">
          <img src="assets/AD_PI_V3/img/ico-safe.png" className="safe-text" alt="" /> Safe | Secure | Privacy Protected
        </div>
      </div>
    </>
  );
};
export default WereYouInjured;
