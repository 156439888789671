import React from "react";
import FbViewPixel from "../../../../Utility/FbViewPixel";

const Header = () => {
  return (
    <>
      <FbViewPixel />

      <div style={{ backgroundColor: "#015882" }}>
        <p
          className="mb-0 text-white text-center py-2"
          style={{ fontSize: "14px" }}
        >
          Claims Gurus is a trading style of Accell Digital which is a
          registered claims management company, and we receive payments from our
          panel solicitors for our service.
        </p>
      </div>

      <header className="d-block d-md-block p-2 bg-white">
        <div className="container text-center">
          <img src="assets/AD_PI_V4/img/logo1.png" className="logo_1" alt="" />
        </div>
      </header>
    </>
  );
};

export default Header;
