import React from "react";
import { useFormContext } from "react-hook-form";
import { getQuestion } from "../../../../Constants/QuestionsV5";
import GtmDataLayer from "../../GtmDataLayer";
import RadioQuestion from "./RadioQuestion";

const MedicalAttention = ({ name, index, next, prev }) => {
  const { trigger, watch } = useFormContext();
  const question = getQuestion(name);

  const proceed = (e) => {
    trigger(name).then((isValid) => {
      if (!isValid) return;

      next();

      GtmDataLayer({
        question: question?.tag,
        answer: watch(name),
      });
    });
  };

  return (
    <>
      <RadioQuestion
        question={question}
        name={name}
        index={index}
        next={proceed}
        prev={prev}
      />
    </>
  );
};

export default MedicalAttention;
