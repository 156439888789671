import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import useJourneyStats from "../../Hooks/useJourneyStats";
import useMultiPartForm from "../../Hooks/useMultiPartForm";
import { useQueryString } from "../../Hooks/useQueryString";
import { useUuid } from "../../Hooks/useUuid";
import HowDidYouGetInjured from "../Includes/Layouts/AD_PI_CC_V5/HowDidYouGetInjured";
import Loader from "../Includes/Layouts/AD_PI_CC_V5/Loader";
import MedicalAttention from "../Includes/Layouts/AD_PI_CC_V5/MedicalAttention";
import ProgressBars from "../Includes/Layouts/AD_PI_CC_V5/ProgressBar";
import TypeOfInjury from "../Includes/Layouts/AD_PI_CC_V5/TypeOfInjury";
import WereYouInjured from "../Includes/Layouts/AD_PI_CC_V5/WereYouInjured";
import WorkingWithSolicitor from "../Includes/Layouts/AD_PI_CC_V5/WorkingWithSolicitor";

const KEY_MAP = {
  were_you_injured: "question_1",
  working_with_attorney: "question_2",
  how_did_you_injured: "question_3",
  type_of_injury: "question_4",
  medical_attention: "question_6",
};

const LOADER_TIMEOUT = 6000;

const field = "pid";

const FormAD_PI_CC_V5 = ({ nextPage, splitName }) => {
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useQueryString();
  const { getUuid, uuid } = useUuid();

  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const { watch, handleSubmit } = formMethods;
  // console.log(watch());

  const questionSection = useRef(null);

  const { saveJourneyStats } = useJourneyStats();
  const saveEventStat = useCallback(
    async (questionId, optionId = "") => {
      const eventType = optionId ? "click" : "view";
      const uuid = getUuid();

      try {
        await saveJourneyStats(
          uuid,
          "question",
          questionId.replace("question_", ""),
          optionId,
          eventType, // click, view
          splitName
        );
      } catch (error) {
        console.log({ error });
      }
    },
    [getUuid, saveJourneyStats, splitName]
  );

  const SLIDES = useMemo(
    () => [
      {
        label: "were_you_injured",
        component: WereYouInjured,
        progress: 0,
      },
      {
        label: "working_with_attorney",
        component: WorkingWithSolicitor,
        progress: 18,
      },
      {
        label: "how_did_you_injured",
        component: HowDidYouGetInjured,
        progress: 54,
      },
      {
        label: "type_of_injury",
        component: TypeOfInjury,
        progress: 72,
      },
      {
        label: "medical_attention",
        component: MedicalAttention,
        progress: 100,
      },
    ],
    []
  );

  const { currentIndex, next, prev, isFirstStep, isLastStep } =
    useMultiPartForm(SLIDES, ({ currentStep, lastStep, isForward }) => {
      setTimeout(() => {
        questionSection.current.scrollIntoView({ behavior: "smooth" });
      }, 100);

      console.log({ currentStep, lastStep, isForward });

      if (lastStep !== undefined) {
        const prevKey = SLIDES[lastStep].label;
        const prevValue = watch(prevKey);
        saveEventStat(KEY_MAP[prevKey], prevValue);
      }

      if (isForward && currentStep !== undefined) {
        const currentLabel = SLIDES[currentStep].label;
        const currentValue = watch(currentLabel);
        saveEventStat(KEY_MAP[currentLabel], currentValue);
      }
    });

  const hasUuid = uuid ? true : false;
  useEffect(() => {
    if (!hasUuid) return;

    // * for first question
    const currentLabel = SLIDES[0].label;
    saveEventStat(KEY_MAP[currentLabel]);
  }, [hasUuid]);

  const { saveDataIngestion, isLoading } = useDataIngestion();

  const history = useHistory();

  const [showLoader, setShowLoader] = useState(false);

  const submitForm = useCallback(
    async (formData) => {
      if (isLoading) return;

      console.log({ formData });

      const lastQuestionLabel = SLIDES[SLIDES.length - 1].label;
      saveEventStat(KEY_MAP[lastQuestionLabel], watch(lastQuestionLabel));

      Object.keys(KEY_MAP).forEach((key) => {
        formData[KEY_MAP[key]] = formData[key];
      });

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("uuid", uuid);

      setShowLoader(true);

      try {
        const request = saveDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          splitName,
          queryString,
          visitorParameters.data
        );

        const [formSUbmitResult] = await Promise.all([
          request,
          new Promise((resolve) => setTimeout(resolve, LOADER_TIMEOUT)),
        ]);

        if (formSUbmitResult.data.status !== "Success") return;
      } catch (error) {
        console.log({ error });
        setShowLoader(false);
      }

      if (!queryString || queryString.indexOf("&" + field + "=") === -1) {
        window.scrollTo(0, 0);
        history.push(
          "/" + nextPage + "?uuid=" + uuid + "&splitName=" + splitName
        );
        return;
      }

      history.push(
        "/fbpixel?split_name=" + splitName + "&next_page=" + nextPage
      );
    },
    [
      SLIDES,
      history,
      isLoading,
      nextPage,
      queryString,
      saveDataIngestion,
      saveEventStat,
      splitName,
      uuid,
      visitorParameters.data,
      visitorParameters.visitor_parameters,
      watch,
    ]
  );

  return (
    <>
      <ProgressBars value={SLIDES[currentIndex]?.progress} />

      <FormProvider {...formMethods}>
        <form
          method="post"
          onSubmit={handleSubmit((data) => {
            submitForm(data);
          })}
          disabled={isLoading}
          autoComplete="off"
        >
          <div
            // className="w-100  inner right"
            id="questionSec"
            ref={questionSection}
            style={
              !isFirstStep
                ? {
                    display: "block",
                  }
                : {}
            }
          >
            {showLoader && <Loader timeout={LOADER_TIMEOUT} />}

            {SLIDES.map(({ component: SlideComponent, label }, index) => {
              const isActiveSlide = currentIndex === index;

              return (
                <div
                  key={label}
                  className={isActiveSlide && !isLoading ? "" : "d-none"}
                >
                  <SlideComponent
                    name={label}
                    index={index}
                    next={
                      isLastStep
                        ? handleSubmit((data) => {
                            submitForm(data);
                          })
                        : next
                    }
                    prev={prev}
                  />
                </div>
              );
            })}
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default FormAD_PI_CC_V5;
