import React, { useState } from "react";
import PrivacyPopUp from "./PrivacyPopUp";

const ConsentSection = () => {
  const [privacyShow, setPrivacyShow] = useState(false);

  return (
    <>
      <div className="text-center safe-text">
        <img src="assets/AD_PI_V3/img/ico-safe.png" className="safe-text" alt="" /> Safe | Secure | Privacy Protected
      </div>
      <div className="form-check offset-lg-2 col-lg-8 offset-md-1 col-md-10 col-sm-12 col-12 py-3 text-center">
        <label className="form-check-label text-black fs-6 mt-0" htmlFor="accept">
          I consent for Accell Digital Limited to process the above data and (personal injury) health data to contact me
          in relation to a possible claim. To read more about how we process your personal data, please see our{" "}
          <a onClick={() => setPrivacyShow(true)}><u>Privacy Policy</u></a>.
        </label>
      </div>
      <PrivacyPopUp privacyShow={privacyShow} setPrivacyShow={setPrivacyShow} />
    </>
  );
};

export default ConsentSection;
