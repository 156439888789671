import React, { useState, useContext } from "react";
import RadioButton from "../../../UI/RadioButton";
import Popup from "./Popup";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useVisitorAnswer } from "../../../../Hooks/useVisitorAnswer";
import ConsentSection from "./ConsentSection";

const WorkingWithAttorney = ({
  className,
  slideChange,
  textHeading,
  validation,
  prevSlide,
}) => {
  const [active3, setActive3] = useState("");
  const [active4, setActive4] = useState("");
  const [shwPopup, setshwPopup] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { saveAnswer } = useVisitorAnswer();

  const nextSlide = async (e) => {
    if (e.target.value == 4) {
      setActive4("active");
    } else {
      setActive4("");
    }

    if (e.target.value == 3) {
      // const saveAns = await saveAnswer(3, e.target.value, visitorParameters.visitor_parameters);
      setActive3("active");
      setshwPopup(true);
      return false;
    } else {
      setActive3("");
    }

    slideChange(e);
  };

  return (
    <>
      <Popup
        show={shwPopup}
        setshwPopup={setshwPopup}
        message="Sorry, If you have received solicitor assistance, we can't help you"
      />
      <div id="slide2" className={className}>
        <div className="col-lg-12 col-md-12 form_cont text-center  p-3">
          <div className="my-3">
            <label className="fw-bold mb-1 qstn_1">{textHeading}</label>

            <RadioButton
              value="3"
              name="working_with_attorney"
              className="rradio-button custom-radio-button "
              labelName="Yes"
              labelClassName={`icon_option text-white fw-bold  mb-3 ${active3}`}
              onClick={nextSlide}
              validation={validation()}
            />

            <RadioButton
              value="4"
              name="working_with_attorney"
              className="radio-button custom-radio-button  next-but"
              labelName="No"
              labelClassName={`icon_option text-white fw-bold nopad ${active4}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <a
            name="type_of_injury"
            className="back_btn sctop1"
            onClick={prevSlide}
          >
            <img src="assets/AD_PI_V4/img/arrow.png" alt="" /> Previous{" "}
          </a>
        </div>
        <div className="text-center safe-text p-3">
          <img
            src="assets/AD_PI_V4/img/ico-safe.png"
            className="safe-text"
            alt=""
          />{" "}
          Safe | Secure | Privacy Protected
        </div>
      </div>
    </>
  );
};
export default WorkingWithAttorney;
