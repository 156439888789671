import React, { useEffect } from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import "../../../assets/AD_PI_CC_V5/css/animate.css";
import "../../../assets/AD_PI_CC_V5/css/style.scss";
import arrow from "../../../assets/AD_PI_CC_V5/img/arrow.gif";
import FormAD_PI_CC_V5 from "../../Forms/FormAD_PI_CC_V5";
import Footer from "../../Includes/Layouts/AD_PI_CC_V5/Footer";
import Header from "../../Includes/Layouts/AD_PI_CC_V5/Header";
import { DynamicRoutingSplit } from "../../../Utility/DynamicRoutingSplit";

function AD_PI_CC_V5() {
  const { DynamicRouteSplitName, DynamicRouteNextPage } = DynamicRoutingSplit(
    "AD_PI_CC_V5",
    "AD_PI_CC_V5"
  );

  useEffect(() => {
    localStorage.setItem("split_name", DynamicRouteSplitName);
  }, []);

  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName={DynamicRouteSplitName} />

      <div id="AD_PI_CC_V5">
        <Header />

        <section className="py-0 py-md-2 SECT ">
          <div className="container p-0">
            <div className="form_part bg-white px-0 px-md-2 py-0 py-md-3">
              <h1 className="d-none d-sm-block d-md-block d-lg-block text-center fw-bold">
                Take This 30 Second Assessment To Help Maximize Your Potential
                Compensation
              </h1>
              <h4 className="d-none d-sm-block d-md-block d-lg-block text-center">
                We offer a FREE no obligation, no win-no fee consultation. Get
                started below by answering a few questions. It's quick and easy.
              </h4>
              <div className="sm-device-txt">
                <h1 className="d-block d-sm-none d-md-none d-lg-none text-center text-white fw-bold">
                  Let our expert team help you decide
                </h1>
                <h2 className="d-block d-sm-none d-md-none d-lg-none text-center">
                  By answering a few simple questions, we will be able to see if
                  you could be entitled to thousands in compensation for a
                  personal injury including: accidents at work, road traffic
                  accidents, slips trip and falls + more.
                </h2>
              </div>
              <p>
                <strong>
                  <em>*Quiz may take a few seconds to load*</em>
                </strong>
              </p>
              <div className="arrow_gif">
                <img src={arrow} />
              </div>

              <FormAD_PI_CC_V5
                splitName={DynamicRouteSplitName}
                nextPage={DynamicRouteNextPage}
              />
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default AD_PI_CC_V5;
