import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../UI/InputButton";
import { useSignature } from "../../Hooks/useSignature";
import { signCheckText } from "../../Constants/Constants";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../Utility/CheckUUID";


const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const Signature = () => {
  const { isCheck} = CheckUUID();
  isCheck();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (response.data.status === "Success") {
          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == '') {
            localStorage.setItem("formData", JSON.stringify(formdata));
          }else{
            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
          history.push(
            "/proceed?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
    <GetVisitorsParams />
      <div className="TMS_sign">
        <section className="sign py-2">
          <div className="container">
            <div className="row">
              <header className="d-none d-lg-block">
                <div className="container">
                  <div className="row">
                    <div className="logo-part col-lg-12 col-md-12 col-12  text-center">
                      <img src="/assets/Signature/img/logo-white.png" alt="" />
                    </div>
                    <div className="col-lg-6 offset-lg-2 col-md-8 col-12 text-center d-none d-md-block "></div>
                  </div>
                </div>
              </header>
            </div>
          </div>
        </section>
        <section className="signature-section">
          <div className="container">
            <div className="row ">
              <div className="offset-lg-2 col-lg-8 col-12 sign-question Larger shadow">
                <h2>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text
                </h2>
                <div className="offset-lg-2 col-lg-8 col-12">
                  <ul>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                    <li>Lorem Ipsum is simply dummy text of the printing</li>
                  </ul>
                </div>
                <div className="sign-div anim_ylw py-3 px-3">
                  <div className="mb-3">
                    <div className="signature_canvaseWrap">
                      <div>
                        {(() => {
                          if (deviceType === "mobile") {
                            return (
                              <MobileOnlyView className="mob-canvas">
                                <SignatureCanvas
                                  canvasProps={{
                                    id:"myID",
                                    width: 300,
                                    height: 300,
                                    className:
                                      "signature-pad anim_bt d-lg-block mob-canvas",
                                  }}
                                  ref={signPadRef}
                                  onEnd={() => {
                                    if (!signPadRef.current.isEmpty()) {
                                      dispatch({
                                        type: "signPadChange",
                                        payload: { value: false },
                                      });
                                      dispatch({
                                        type: "validSignature",
                                        payload: { value: false },
                                      });
                                      document
                                        .getElementById("acceptterms")
                                        .classList.add("anim_ylw");
                                    }
                                  }}
                                />
                              </MobileOnlyView>
                            );
                          } else if (deviceType === "tablet" || isIPad13) {
                            return (
                              <TabletView className="tab-canvas">
                                <SignatureCanvas
                                  canvasProps={{
                                    id:"myID",
                                    width: 580,
                                    height: 300,
                                    className:
                                      "signature-pad anim_bt d-lg-block tab-canvas",
                                  }}
                                  ref={signPadRef}
                                  onEnd={() => {
                                    if (!signPadRef.current.isEmpty()) {
                                      dispatch({
                                        type: "signPadChange",
                                        payload: { value: false },
                                      });
                                      dispatch({
                                        type: "validSignature",
                                        payload: { value: false },
                                      });
                                      document
                                        .getElementById("acceptterms")
                                        .classList.add("anim_ylw");
                                    }
                                  }}
                                />
                              </TabletView>
                            );
                          } else {
                            return (
                              <BrowserView className="web-canvas">
                                <SignatureCanvas
                                  canvasProps={{
                                    id:"myID",
                                    width: 700,
                                    height: 300,
                                    className:
                                      "signature-pad anim_bt d-lg-block web-canvas",
                                  }}
                                  ref={signPadRef}
                                  onEnd={() => {
                                    if (!signPadRef.current.isEmpty()) {
                                      dispatch({
                                        type: "signPadChange",
                                        payload: { value: false },
                                      });
                                      dispatch({
                                        type: "validSignature",
                                        payload: { value: false },
                                      });
                                      document
                                        .getElementById("acceptterms")
                                        .classList.add("anim_ylw");
                                    }
                                  }}
                                />
                              </BrowserView>
                            );
                          }
                        })()}

                        {state.isAgreed && (
                          <div className="sign_msg col-12 text-center">
                            Please tick to show your authorisation for us to
                            proceed.
                          </div>
                        )}
                        {state.isCanvasEmpty && (
                          <div className="sign_msg col-12 text-center sign_msg" id="signatures_required">
                            Signature is required!
                          </div>
                        )}
                        {state.isValid && (
                          <div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">
                            Draw valid signature!
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="form-check my-3">
                      <CheckBox
                      dataId="acceptterms"
                      anchorText=""
                      anchorStyle=""
                      href=""
                      onChange={(e) => setIsChecked(!isChecked)}
                      className="form-check-input"
                      labelClassName="form-check-label sign-custom-control-label"
                      value={isChecked}
                      CheckBoxText="<label className='form-check-label' htmlFor='acceptterms'>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                      </label>"
                    />
                  </div>
                  <input
                    type="button"
                    id=""
                    name=""
                    className="btn-clear"
                    value="Clear"
                    onClick={clearSign}
                  />
                  <input
                    className="btn btn-submit"
                    type="button"
                    onClick={handleClick}
                    value="Submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer>
          <div className="container">
            <div className="col-xl-8 col-lg-8 text-start">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
              <ul>
                <li>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#privacy">
                    Privacy and Cookie Policy
                  </a>
                </li>
                <li>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#terms">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#complaints"
                  >
                    {" "}
                    Complaints Procedure
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
export default Signature;
